import React, {
    useEffect,
    useState
} from "react";
import {
    Accordion,
    AccordionItem,
    Link,
    Spacer
} from "@nextui-org/react";
import Ajax from "../inc/js/Ajax";
import {
    QuestionMark_96_Blue_200
} from "../inc/images/svgs";

export default function FAQ() {
    const [FAQData, setFAQData] = useState([]);

    useEffect(() => {
        Ajax.request({
            url:"/JudgeAutos/readFAQs",
            success:function(reply) {
                if(reply.data) {
                    setFAQData(reply.data);
                }
            }
        });
    }, []);

    return(
        <div className="flex-col text-center">
            <div className="relative w-full h-64 mb-12">
                <div className="absolute inset-0 flex justify-center items-center z-0">
                    <QuestionMark_96_Blue_200 />
                </div>
                <div className="relative z-10 flex justify-center items-center h-full">
                    <h1 className="text-4xl font-bold flex flex-col items-center">
                    <span>Frequently</span>
                    <span>Asked</span>
                    <span>Questions</span>
                    </h1>
                </div>
            </div>
            
            {/* This is a cascading FAQ header.  Just commenting to save in case I ever want to revisit
            <div className="relative">
                <div className="absolute inset-0 flex items-center justify-center z-0">
                    <QuestionMark_96_Blue_200 />
                </div>
                <div className="relative z-10">
                    <div className="inline-flex items-baseline w-full">
                    <h1 className="text-5xl">F</h1>
                    <p className="text-3xl mr-2">requently</p>
                    </div>
                    <div className="justify-center inline-flex items-baseline w-full">
                    <h1 className="text-5xl">A</h1>
                    <p className="text-3xl mr-2">sked</p>
                    </div>
                    <div className="justify-end inline-flex items-baseline w-full">
                    <h1 className="text-5xl">Q</h1>
                    <p className="text-3xl">uestions</p>
                    </div>
                </div>
            </div> 
            */}
            
            <div className="w-full max-w-full mx-auto p-5 border-solid border-2 rounded-2xl">
                <Accordion>
                    {FAQData.map((data, index) => (
                        <AccordionItem
                            key={index}
                            aria-label={`FAQ Accordion ${index}`}
                            classNames={{
                                title: "text-center",
                                content: "text-left"
                            }}
                            title={data.question}
                        >
                            <div className="-mt-3 sm:max-w-sm md:max-w-full md:min-w-full" dangerouslySetInnerHTML={{ __html: data.answer }} />
                        </AccordionItem>
                    ))}
                </Accordion>
            </div>
            <Spacer y={10} />
            <p className="text-2xl -mb-10">
                Can't find the answer? <Link href="/ContactUs" className="text-2xl">Contact Us</Link> to ask your question!
            </p>
        </div>
    );
}