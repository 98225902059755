import React, {
    useEffect,
    useState
} from "react";
import {
    useNavigate
} from 'react-router-dom';
import Ajax from "../inc/js/Ajax";
import {
    Bulb_4_Blue,
    Bulb_6_Blue,
    PlusCircle_6_Blue,
    Trash_8_Red
} from "../inc/images/svgs";
import {
    Button,
    Input,
    Popover,
    PopoverTrigger,
    PopoverContent,
    Spacer,
    Tab,
    Tabs,
    Table,
    TableBody,
    TableCell,
    TableColumn,
    TableHeader,
    TableRow,
} from "@nextui-org/react";
import swal from "sweetalert2";

export default function Forms({clientInformation}) {
    const navigate = useNavigate();
    const [registrationForms, setRegistrationForms] = useState({});
    const [judgeForms, setJudgeForms] = useState({});
    const [classLists, setClassLists] = useState({});
    const [isHovered, setIsHovered] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
    const [selectedTab, setSelectedTab] = useState();
    const [newFormData, setNewFormData] = useState({});

    useEffect(() => {
        if (clientInformation.userInformation.loggedIn === false) {
            navigate("/Login");
        }
    }, [clientInformation]);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);

        if (queryParams.get("tab") === "Registration") {
            setSelectedTab("Registration");
        }
        
        if (queryParams.get("tab") === "Judging") {
            setSelectedTab("Judging");
        }
        
        if (queryParams.get("tab") === "Classes") {
            setSelectedTab("Classes");
        }

        if (window.history.replaceState) {
            window.history.replaceState(null, null, window.location.pathname);
        }

        Ajax.request({
            url:"/JudgeAutos/readForms",
            success:function(reply) {
                setRegistrationForms(reply.data.registrationForms);
                setJudgeForms(reply.data.judgeForms);
                setClassLists(reply.data.classLists);
            }
        });
    }, []);

    useEffect(() => {
            const handleResize = () => {
                const newWidth = window.innerWidth;
                const isNowMobile = newWidth < 640;
        
                if (Math.abs(newWidth - screenWidth) > 100) {
                    setScreenWidth(newWidth);
                    setIsMobile(isNowMobile);
                }
            };
        
            window.addEventListener("resize", handleResize);
            return () => window.removeEventListener("resize", handleResize);
        }, [screenWidth]);

    const createClassList = () => {
        if (Object.keys(newFormData).length !== 2) {
            swal.fire({
                title: "Missing fields",
                text: "Please enter a Name & Description",
                icon: "info",
                timer: 3000
            })
        } else {
            Ajax.request({
                url:"/JudgeAutos/createClassList",
                jsonData: {
                    classListName: newFormData["name"],
                    classListDescription: newFormData["description"]
                },
                success:function(reply) {
                    swal.fire({
                        title: "Success",
                        text: `${reply.data.name} was created`,
                        icon: "success",
                        timer: 1500
                    }).then(() =>{
                        navigate(`/ClassList?classListId=${reply.data.id}`);
                    })
                }
            });
        }
    }

    const createForm = (formType) => {
        if (Object.keys(newFormData).length !== 2) {
            swal.fire({
                title: "Missing fields",
                text: "Please enter a Name & Description",
                icon: "info",
                timer: 3000
            })
        } else {
            Ajax.request({
                url:"/JudgeAutos/createForm",
                jsonData: {
                    formType: formType,
                    formName: newFormData["name"],
                    formDescription: newFormData["description"],
                },
                success:function(reply) {
                    swal.fire({
                        title: "Success",
                        text: `${newFormData["name"]} was created`,
                        icon: "success",
                        timer: 1500
                    }).then(() =>{
                        navigate(`/${formType.charAt(0).toUpperCase() + formType.slice(1)}Form?${formType}FormId=${reply.data.id}`);
                    })
                }
            });
        }
    }

    const deleteForm = (formId, formType) => {
        const isBasic = judgeForms[formId]?.type === "basic" || registrationForms[formId]?.type === "basic";
        const formName = formType === "judge" ?
            judgeForms[formId].formName :
            formType === "class" ?
            classLists[formId].formName :
            registrationForms[formId].formName
        ;

        if (isBasic) {
            swal.fire({
                title: "Base forms cannot be deleted",
                text: `${formName} is a base form and cannot be deleted`,
                icon: "success"
            })
        } else {
            Ajax.request({
                url:"/JudgeAutos/deleteForm",
                jsonData: {
                    formId: formId,
                    formType: formType
                },
                success:function(reply) {
                    swal.fire({
                        title: `${reply.data}`,
                        text: `${formName} was deleted`,
                        icon: "success",
                        didClose:(() => {
                            window.location.href = `/Forms?tab=${selectedTab}`;
                        })
                    })
                }
            });
        }
    }

    const handleListHover = (index) => {
        setIsHovered(index);
    }

    const handleListUnhover = () => {
        setIsHovered(false);
    }

    const loadRegistrationForm = (registrationFormId) => {
        navigate(`/RegistrationForm?registrationFormId=${registrationFormId}`);
    }

    const loadJudgeForm = (judgeFormId) => {
        navigate(`/JudgeForm?judgeFormId=${judgeFormId}`);
    }

    const loadClassList = (classListId) => {
        navigate(`/ClassList?classListId=${classListId}`);
    }

    // WORK AROUND FOR NEXTUI ISSUE 1968
    const dummyKeyboardDelegate = Object.fromEntries(
        [
            "getKeyBelow",
            "getKeyAbove",
            "getKeyLeftOf",
            "getKeyRightOf",
            "getKeyPageBelow",
            "getKeyPageAbove",
            "getFirstKey",
            "getLastKey",
            // HAVE TO ignore this one
            // "getKeyForSearch"
        ].map((name) => [name, () => null]),
    );

    return(
        <div className="flex-col text-center">
            <h1 className="text-5xl">Forms</h1>
            <Spacer y={10} />
            <Tabs
                selectedKey={selectedTab}
                onSelectionChange={() => {
                    setSelectedTab();
                    setNewFormData({});
                }}
            >
                <Tab key="Registration" title="Registration" aria-label="Registration forms tab">
                    <Table
                        isStriped
                        aria-label="Registration Forms"
                        keyboardDelegate={dummyKeyboardDelegate}
                        topContent={
                            <div className="text-xs text-left flex items-center gap-2">
                                <Popover>
                                    <PopoverTrigger>
                                        <Button isIconOnly variant="light" size="sm">
                                            <Bulb_4_Blue />
                                        </Button>
                                    </PopoverTrigger>
                                    <PopoverContent>
                                    <div className="px-1 py-2">
                                        <div className="flex items-center justify-center text-center text-small font-bold">
                                            <Bulb_6_Blue />What's this?
                                        </div>
                                        <div className="text-tiny">
                                            Custom forms can be made at anytime, but can only be used with the Unlimited Package
                                        </div>
                                    </div>
                                    </PopoverContent>
                             </Popover>
                                Custom forms available in Unlimited Package only
                            </div>
                        }
                    >
                        <TableHeader>
                            <TableColumn className="text-center">Name</TableColumn>
                            <TableColumn className="text-center">Description</TableColumn>
                            <TableColumn className="text-center">Date Created</TableColumn>
                        </TableHeader>
                        <TableBody>
                            {Object.keys(registrationForms).length > 0 && (
                                Object.keys(registrationForms).map((key, index) => (
                                    <TableRow
                                        key={key}
                                        style={{ cursor: "pointer"}}
                                        onClick={() => (loadRegistrationForm(key))}
                                        onMouseEnter={() => handleListHover(key)}
                                        onMouseLeave={handleListUnhover}
                                    >
                                        <TableCell>{registrationForms[key]["formName"]}</TableCell>
                                        <TableCell>{registrationForms[key]["formDescription"]}</TableCell>
                                        <TableCell>
                                            <div className="flex justify-between items-center">
                                                {new Date(registrationForms[key]["dateCreated"]).toLocaleDateString("en-US")}
                                                <div
                                                    className={`
                                                        transition-opacity
                                                        duration-200
                                                        ${
                                                            (isMobile || isHovered === key) && registrationForms[key]["type"] !== "basic" ?
                                                                "opacity-100" :
                                                                "opacity-0"
                                                        }
                                                    `}
                                                >
                                                    <Button
                                                        isIconOnly
                                                        size="sm"
                                                        variant="light"
                                                        onClick={() => {
                                                            swal.fire({
                                                                title: "Confirm Deletion",
                                                                text: `Delete ${registrationForms[key]["formName"]} form?`,
                                                                icon: "warning",
                                                                showConfirmButton: true,
                                                                confirmButtonText: "Delete",
                                                                showCancelButton: true,
                                                            }).then((result) => {
                                                                if (result.isConfirmed) {
                                                                    deleteForm(key, "registration")
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        <Trash_8_Red />
                                                    </Button>
                                                </div>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                            <TableRow>
                                <TableCell>
                                    <Input
                                        value={newFormData["name"]}
                                        placeholder="Name"
                                        type="text"
                                        color="primary"
                                        variant="underlined"
                                        onChange={(e) =>
                                            setNewFormData((prev) => ({
                                                ...prev,
                                                name: e.target.value
                                            }))
                                        }
                                        classNames={{input: "text-center bg-transparent", inputWrapper: ["border-0", "shadow-none"]}}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Input
                                        value={newFormData["description"]}
                                        placeholder="Description"
                                        type="text"
                                        color="primary"
                                        variant="underlined"
                                        onChange={(e) =>
                                            setNewFormData((prev) => ({
                                                ...prev,
                                                description: e.target.value
                                            }))
                                        }
                                        classNames={{input: "text-center bg-transparent", inputWrapper: ["border-0", "shadow-none"]}}
                                    />
                                </TableCell>
                                <TableCell  onClick={() => createForm("registration")} style={{ cursor: 'pointer'}}>
                                    <PlusCircle_6_Blue />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Tab>
                <Tab key="Judging" title="Judging" aria-label="Judge forms tab" >
                    <Table
                        isStriped
                        aria-label="Judge forms"
                        keyboardDelegate={dummyKeyboardDelegate}
                        topContent={
                            <div className="text-xs text-left flex items-center gap-2">
                                <Popover>
                                    <PopoverTrigger>
                                        <Button isIconOnly variant="light" size="sm">
                                            <Bulb_4_Blue />
                                        </Button>
                                    </PopoverTrigger>
                                    <PopoverContent>
                                    <div className="px-1 py-2">
                                        <div className="flex items-center justify-center text-center text-small font-bold">
                                            <Bulb_6_Blue />What's this?
                                        </div>
                                        <div className="text-tiny">
                                            Custom forms can be made at anytime, but can only be used with the Unlimited Package
                                        </div>
                                    </div>
                                    </PopoverContent>
                            </Popover>
                                Custom forms available in Unlimited Package only
                            </div>
                        }
                    >
                        <TableHeader>
                            <TableColumn className="text-center">Name</TableColumn>
                            <TableColumn className="text-center">Description</TableColumn>
                            <TableColumn className="text-center">Date Created</TableColumn>
                        </TableHeader>
                        <TableBody>
                            {Object.keys(judgeForms).length > 0 && (
                                Object.keys(judgeForms).map((key, index) => (
                                    <TableRow
                                        key={key}
                                        style={{ cursor: "pointer"}}
                                        onClick={() => (loadJudgeForm(key))}
                                        onMouseEnter={() => handleListHover(key)}
                                        onMouseLeave={handleListUnhover}
                                    >
                                        <TableCell>{judgeForms[key]["formName"]}</TableCell>
                                        <TableCell>{judgeForms[key]["formDescription"]}</TableCell>
                                        <TableCell>
                                            <div className="flex justify-between items-center">
                                                {new Date(judgeForms[key]["dateCreated"]).toLocaleDateString("en-US")}
                                                <div
                                                    className={`
                                                        transition-opacity
                                                        duration-200
                                                        ${
                                                            (isMobile || isHovered === key) && judgeForms[key]["type"] !== "basic" ?
                                                                "opacity-100" :
                                                                "opacity-0"
                                                        }
                                                    `}
                                                >
                                                    <Button
                                                        isIconOnly
                                                        size="sm"
                                                        variant="light"
                                                        onClick={() => {
                                                            swal.fire({
                                                                title: "Confirm Deletion",
                                                                text: `Delete ${judgeForms[key]["formName"]} form?`,
                                                                icon: "warning",
                                                                showConfirmButton: true,
                                                                confirmButtonText: "Delete",
                                                                showCancelButton: true,
                                                            }).then((result) => {
                                                                if (result.isConfirmed) {
                                                                    deleteForm(key, "judge");
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        <Trash_8_Red />
                                                    </Button>
                                                </div>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                            <TableRow>
                                <TableCell className="text-center">
                                    <Input
                                        value={newFormData["name"]}
                                        placeholder="Name"
                                        type="text"
                                        color="primary"
                                        variant="underlined"
                                        onChange={(e) =>
                                            setNewFormData((prev) => ({
                                                ...prev,
                                                name: e.target.value
                                            }))
                                        }
                                        classNames={{input: "text-center bg-transparent", inputWrapper: ["border-0", "shadow-none"]}}
                                    />
                                </TableCell>
                                <TableCell className="text-center">
                                    <Input
                                        value={newFormData["description"]}
                                        placeholder="Description"
                                        type="text"
                                        color="primary"
                                        variant="underlined"
                                        onChange={(e) =>
                                            setNewFormData((prev) => ({
                                                ...prev,
                                                description: e.target.value
                                            }))
                                        }
                                        classNames={{input: "text-center bg-transparent", inputWrapper: ["border-0", "shadow-none"]}}
                                    />
                                </TableCell>
                                <TableCell 
                                    className="text-center"
                                    onClick={() => createForm("judge")}
                                    style={{ cursor: 'pointer'}}
                                >
                                    <PlusCircle_6_Blue />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Tab>
                <Tab key="Classes" title="Classes" aria-label="Class lists tab">
                    <Table
                        isStriped
                        aria-label="Class Lists"
                        topContent={
                            <div className="text-xs text-left flex items-center gap-2">
                                <Popover>
                                    <PopoverTrigger>
                                        <Button isIconOnly variant="light" size="sm">
                                            <Bulb_4_Blue />
                                        </Button>
                                    </PopoverTrigger>
                                    <PopoverContent>
                                    <div className="px-1 py-2">
                                        <div className="flex items-center justify-center text-center text-small font-bold">
                                            <Bulb_6_Blue />What's this?
                                        </div>
                                        <div className="text-tiny">
                                            Class Lists can be made at anytime, but can only be used with the Basic Package or higher
                                        </div>
                                    </div>
                                    </PopoverContent>
                             </Popover>
                                Classes available starting with the Basic Package
                            </div>
                        }
                    >
                        <TableHeader>
                            <TableColumn className="text-center">Name</TableColumn>
                            <TableColumn className="text-center">Description</TableColumn>
                            <TableColumn className="text-center">Date Created</TableColumn>
                        </TableHeader>
                        <TableBody>
                            {Object.keys(classLists).length > 0 && (
                                Object.keys(classLists).map((key, index) => (
                                    <TableRow
                                        key={key}
                                        style={{ cursor: "pointer"}}
                                        onClick={() => (loadClassList(key))} 
                                        onMouseEnter={() => handleListHover(key)}
                                        onMouseLeave={handleListUnhover}
                                    >
                                        <TableCell>{classLists[key]["formName"]}</TableCell>
                                        <TableCell>{classLists[key]["formDescription"]}</TableCell>
                                        <TableCell>
                                            <div className="flex justify-between items-center">
                                                {new Date(classLists[key]["dateCreated"]).toLocaleDateString("en-US")}
                                                <div
                                                    className={`
                                                        transition-opacity
                                                        duration-200
                                                        ${
                                                            (isMobile || isHovered === key) ?
                                                                "opacity-100" :
                                                                "opacity-0"
                                                        }
                                                    `}
                                                >
                                                    <Button
                                                        isIconOnly
                                                        size="sm"
                                                        variant="light"
                                                        onClick={() => {
                                                            swal.fire({
                                                                title: "Confirm Deletion",
                                                                text: `Delete ${classLists[key]["formName"]} Class List?`,
                                                                icon: "warning",
                                                                showConfirmButton: true,
                                                                confirmButtonText: "Delete",
                                                                showCancelButton: true,
                                                            }).then((result) => {
                                                                if (result.isConfirmed) {
                                                                    deleteForm(key, "class");
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        <Trash_8_Red />
                                                    </Button>
                                                </div>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                            <TableRow>
                                <TableCell>
                                    <Input
                                        value={newFormData["name"]}
                                        placeholder="Name"
                                        type="text"
                                        color="primary"
                                        variant="underlined"
                                        onChange={(e) =>
                                            setNewFormData((prev) => ({
                                                ...prev,
                                                name: e.target.value
                                            }))
                                        }
                                        classNames={{input: "text-center bg-transparent", inputWrapper: ["border-0", "shadow-none"]}}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Input
                                        value={newFormData["description"]}
                                        placeholder="Description"
                                        type="text"
                                        color="primary"
                                        variant="underlined"
                                        onChange={(e) =>
                                            setNewFormData((prev) => ({
                                                ...prev,
                                                description: e.target.value
                                            }))
                                        }
                                        classNames={{input: "text-center bg-transparent", inputWrapper: ["border-0", "shadow-none"]}}
                                        onClick={(event) => event.stopPropagation()}
                                    />
                                </TableCell>
                                <TableCell  onClick={createClassList} style={{ cursor: 'pointer'}}>
                                    <PlusCircle_6_Blue />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Tab>
            </Tabs>
        </div>
    );
}