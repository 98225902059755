import React, {
    useEffect,
 } from "react";
import { 
    Button,
    Input,
    Link,
    Spacer
} from "@nextui-org/react";
import swal from "sweetalert2";
import Ajax from "../inc/js/Ajax";


export default function Login({clientInformation}) {
    useEffect(() => {
        if(clientInformation.userInformation.loggedIn === true) {
            document.location.href = "/MyAccount";
        } else {
            const queryParams = new URLSearchParams(window.location.search);

            if (queryParams.get("VerifiedEmail") === "true") {
                swal.fire({
                    title: "Email Verified",
                    text: "Thank you for verifying your email, you may now log in!",
                    icon: "success"
                });
            } else if (queryParams.get("AccountCreated") === "true") {
                const mobileEmail = /iPhone|iPad|Android/i.test(navigator.userAgent);
                const htmlMessage = mobileEmail
                    ? "Please check your <a href='message://' style='text-decoration: underline; color: blue;'>email</a> for a verification link to finish setting up your account"
                    : "Please check your email for a verification link to finish setting up your account";

                swal.fire({
                    title: "Account Created!",
                    html: htmlMessage,
                    icon: "success"
                });
            } else if (queryParams.get("VerificationResent") === "true") {
                swal.fire({
                    title: "Sent Verification Email",
                    text: "Another verification email has been sent to your email",
                    icon: "success"
                });
            } else if (queryParams.get("VerificationResent") === "timeLapse") {
                swal.fire({
                    title: "Verification too soon",
                    text: "Please wait before sending another email verification, or check your email for previous verification",
                    icon: "error"
                });
            }

            if (window.history.replaceState) {
                window.history.replaceState(null, null, window.location.pathname);
            }
        }
    }, [clientInformation]);

    const [isVisible, setIsVisible] = React.useState(false);
    const toggleVisibility = () => setIsVisible(!isVisible);

    function login() {
        const form = {
            username:document.getElementById("username").value,
            password:document.getElementById("password").value,
        };

        Ajax.request({
            url:"/JudgeAutos/authenticate",
            jsonData:form,
            success:function(reply) {
                if(reply.login) {
                    document.location.href = "/";
                } else {
                    { swal.fire({
                        title:"Oops!",
                        text:"Incorrect username or password.",
                        icon:"error",
                        timer: 1500
                    }); }
                }
                document.getElementById("username").value = "";
                document.getElementById("password").value = "";
                document.getElementById("username").focus();
            }
        });
    }

    function createAccount() {
        document.location.href = "/CreateAccount"
    }

    function handleKeyDown(event) {
        if(event.key === "Enter") {
            login();
        }
    }

    return(
        <div className="w-full sm:w-1/2 text-center">
            <div className="grid grid-cols-1 gap-4 m-4 p-5 border-solid border-2 rounded-2xl">
                <h1 className="text-5xl">Log In</h1>
                <Spacer y={4} />
                <Input
                    id="username"
                    label="Email"
                    variant="bordered"
                    placeholder="address@domain.com"
                    type={"email"}
                    required={true}
                />
                <Input
                    id="password"
                    label="Password"
                    type={isVisible ? "text" : "password"}
                    required={true}
                    onKeyDown={handleKeyDown}
                    variant="bordered"
                    placeholder="P@zZw0rD!"
                    />
                <Button
                color="primary" 
                variant="solid"
                className="mx-auto"
                onClick={login}>
                    Log In
                </Button>
                <Button
                color="primary"
                variant="bordered"
                className="mx-auto"
                onClick={createAccount}>
                    Create Account
                </Button>
                <Link href="/ForgotPassword" className="place-content-center">Forgot password?</Link>
            </div>
        </div>
    );
}