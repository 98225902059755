import React, {
    useEffect,
    useRef,
    useState
} from "react";
import {
    Form,
    useNavigate
} from 'react-router-dom';
import Ajax from "../inc/js/Ajax";
import {
    PlusCircle_8_Blue,
    Trash_8_Red
} from "../inc/images/svgs";
import {
    Button,
    Divider,
    Input,
    Spacer,
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell,
} from "@nextui-org/react";
import swal from "sweetalert2";

export default function ClassList({clientInformation}) {  
    const navigate = useNavigate();
    const [classes, setClasses] = useState([]);
    const [classListId, setClassListId] = useState();
    const [classListInfo, setClassListInfo] = useState([]);
    const [classInfoUpdates, setClassInfoUpdates] = useState([]);
    const [classListName, setClassListName] = useState();
    const [classChanges, setClassChanges] = useState({});
    const [classListUpdated, setClassListUpdated] = useState(false);
    const [classAdditions, setClassAdditions] = useState([]);
    
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [isHovered, setIsHovered] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 640);

    useEffect(() => {
        if (clientInformation.userInformation.loggedIn === false) {
            navigate("/Login");
        } else {
            const queryParams = new URLSearchParams(window.location.search);
            const param = queryParams.get("classListId") || localStorage.getItem("classListId");
            
            if (!param) {
                swal.fire({
                    title: "Whoopsie Daisy",
                    text: "Missing Class List Id, please try again.",
                    icon: "error",
                    didClose:(() => {
                        navigate("/Forms");
                    })
                })
            } else {
                if (window.history.replaceState) {
                    localStorage.setItem("classListId", param);
                    window.history.replaceState(null, null, window.location.pathname);
                }

                Ajax.request({
                    url:"/JudgeAutos/readClassList",
                    jsonData: {
                        classListId: param
                    },
                    success:function(reply) {
                        setClasses(reply.data.classes);
                        setClassListInfo(reply.data.classListInfo);
                        setClassListId(reply.data.classListInfo.classListId);
                        setClassListName(reply.data.classListInfo.classListName);
                    }
                });
            }
        }
    }, []);

    useEffect(() => {
        const handleResize = () => {
            const newWidth = window.innerWidth;
            const isNowMobile = newWidth < 640;
    
            if (Math.abs(newWidth - screenWidth) > 100) {
                setScreenWidth(newWidth);
                setIsMobile(isNowMobile);
            }
        };
    
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [screenWidth]);

    const updateClassList = () => {
        const preppedClassChanges = Object.entries(classChanges).map(([key, change]) => ({
            classListDataId: key,
            ...classes[key],
            ...change
        }));
        
        const preppedInfoUpdates = Object.keys(classInfoUpdates).length > 0 && [{
            classListName: classInfoUpdates.classListName || classListInfo.classListName,
            classListDescription: classInfoUpdates.classListDescription || classListInfo.classListDescription
        }]

        const allClassNumbers = [
            ...classAdditions.map(c => String(c.classNumber)),
            ...Object.values(classes).map(c => String(c.classNumber))
        ];

        const duplicateClassNumbers = allClassNumbers.filter((num, index, self) => 
            self.indexOf(num) !== index && num !== ''
        );

        if (duplicateClassNumbers.length > 0) {
            swal.fire({
                title: "Whoopsie Daisy",
                html: `
                    Duplicate class numbers found:<br />
                    ${duplicateClassNumbers.join(', ')}
                `,
                icon: "error"
            });
            return;
        }

        Ajax.request({
            url:"/JudgeAutos/updateClassList",
            jsonData: {
                classListId: classListId,
                classAdditions: classAdditions,
                classChanges: preppedClassChanges,
                classInfoUpdates: preppedInfoUpdates
            },
            success:function(reply) {
                swal.fire({
                    title: `${reply.data}`,
                    text: `Changes saved to ${classListName}`,
                    icon: "success",
                    didClose:(() => {
                        window.location.href = `/ClassList?classListId=${classListId}`;
                    })
                })
            }
        });
    }

    const deleteClassListEntry = (classListDataId) => {
        Ajax.request({
            url:"/JudgeAutos/deleteClassListEntry",
            jsonData: {
                classListDataId: classListDataId
            },
            success:function(reply) {
                swal.fire({
                    title: reply.data,
                    text: `Class deleted from ${classListName}`,
                    icon: "success",
                    didClose:(() => {
                        window.location.href = `/ClassList?classListId=${classListId}`;
                    })
                })
            }
        });
    }

    const addRow = () => {
        setClassAdditions((prev) => ([
            ...prev,
            {
                classNumber: '',
                className: '',
                classDescription: '' 
            }
        ]));
    }

    const handleUpdateNewRow = (index, id, value) => {
        setClassAdditions((prev) =>
            prev.map((row, rowIndex) =>
                rowIndex === index ? { ...row, [id]: value } : row
            )
        );
        setClassListUpdated(true);
    };

    const deleteClassListAddition = (indexToRemove) => {
        setClassAdditions((prevAdditions) => 
            prevAdditions.filter((_, index) => index !== indexToRemove)
        );
    };

    const handleClassChanges = (key, id, value) => {
        setClasses((prev) => ({
            ...prev,
            [key]: {
                ...prev[key],
                [id]: value
            }
        }))

        setClassChanges((prev) => ({
            ...prev,
            [key]: {
                ...prev[key],
                [id]: value
            }
        }))

        setClassListUpdated(true);
    };

    const handleClassInfoUpdates = (key, value) => {
        setClassListUpdated(true);

        setClassListInfo((prev) => ({
            ...prev,
            [key]: value
        }));

        setClassInfoUpdates((prev) => ({
            ...prev,
            [key]: value
        }));
    }

    const handleListHover = (index) => {
        setIsHovered(index);
    }

    const handleListUnhover = () => {
        setIsHovered(false);
    }

    // WORK AROUND FOR NEXTUI ISSUE 1968
    const dummyKeyboardDelegate = Object.fromEntries(
        [
          "getKeyBelow",
          "getKeyAbove",
          "getKeyLeftOf",
          "getKeyRightOf",
          "getKeyPageBelow",
          "getKeyPageAbove",
          "getFirstKey",
          "getLastKey",
          // HAVE TO ignore this one
          // "getKeyForSearch"
        ].map((name) => [name, () => null]),
      );

    return(
        <div className="flex-col text-center">
            {classListInfo && (
                <div>
                    <h1 className="text-5xl">{classListName}</h1>
                    <Spacer y={2} />
                    <h2 className="text-3xl">Class List</h2>
                </div>
            )}
            <Spacer y={10} />
            {classListInfo && (
                <div>
                    <div className="flex justify-between gap-2">
                        <Form className="w-full flex-grow">
                            <Input
                                key="classListName"
                                id="classListName"
                                label="List Name"
                                value={classListInfo.classListName}
                                onChange={(e) => handleClassInfoUpdates(e.target.id, e.target.value)}
                                variant="bordered"
                            />
                        </Form>
                        <Form className="w-full flex-grow">
                            <Input
                                key="classListDescription"
                                id="classListDescription"
                                label="Description"
                                value={classListInfo.classListDescription}
                                onChange={(e) => handleClassInfoUpdates(e.target.id, e.target.value)}
                                variant="bordered"
                            />
                        </Form>
                        <Form className="w-full flex-grow">
                            <Input
                                isReadOnly
                                key="classListDate"
                                id="classListDate"
                                label="Date Created"
                                value={new Date(classListInfo.dateCreated).toLocaleDateString("en-US")}
                                variant="flat"
                            />
                        </Form>
                    </div>
                    <Spacer y={3} />
                    <Button
                        onClick={() => {
                            if (classListUpdated) {
                                updateClassList()
                            } else {
                                swal.fire({
                                    title: "No changes made",
                                    text: "Please make your changes before saving",
                                    icon: "error",
                                    timer: 1500
                                })
                            }
                        }}
                        color="primary"
                    >
                        Save
                    </Button>
                </div>
            )}

            <Spacer y={5} />
            <Divider />
            <Spacer y={5} />
            
            <Table
                isStriped
                bottomContent={
                    <div className="flex justify-center">
                        <Button
                            variant="light"
                            className="w-1/8"
                            onClick={addRow}
                        >
                            <PlusCircle_8_Blue />
                        </Button>
                    </div>
                }
                keyboardDelegate={dummyKeyboardDelegate}
                aria-label="Event registrations"
            >
                <TableHeader>
                    <TableColumn>Number</TableColumn>
                    <TableColumn>Name</TableColumn>
                    <TableColumn>Description</TableColumn>
                </TableHeader>
                <TableBody>
                    {Object.keys(classes).length > 0 && (
                        Object.entries(classes).map(([key, value]) => (
                            <TableRow
                                key={key}
                                onChange={(e) => handleClassChanges(key, e.target.id, e.target.value)}
                                onMouseEnter={() => handleListHover(key)}
                                onMouseLeave={handleListUnhover}
                            >
                                <TableCell key={`${key}-classNumber-cell`} >
                                    <Input
                                        key={`${key}-classNumber`}
                                        id="classNumber"
                                        type="number"
                                        color="primary"
                                        variant="underlined"
                                        value={classes[key]["classNumber"]}
                                        classNames={{input: "text-center bg-transparent", inputWrapper: ["border-0", "shadow-none"]}}
                                    />
                                </TableCell>
                                <TableCell key={`${key}-className-cell`} >
                                    <Input
                                        key={`${key}-className`}
                                        id="className"
                                        type="text"
                                        color="primary"
                                        variant="underlined"
                                        value={classes[key]["className"]}
                                        classNames={{input: "text-center bg-transparent", inputWrapper: ["border-0", "shadow-none"]}}
                                    />
                                </TableCell>
                                <TableCell key={`${key}-classDescription-cell`} >
                                    <Input
                                        key={`${key}-classDescription`}
                                        id="classDescription"
                                        type="text"
                                        color="primary"
                                        variant="underlined"
                                        value={classes[key]["classDescription"]}
                                        classNames={{input: "text-center bg-transparent", inputWrapper: ["border-0", "shadow-none"]}}
                                        endContent={ 
                                            <div
                                                className={`
                                                    transition-opacity
                                                    duration-200
                                                    ${
                                                        isHovered === key || isMobile ?
                                                        'opacity-100' :
                                                        'opacity-0'
                                                    }
                                                `}>
                                                <button onClick={() => deleteClassListEntry(key)}>
                                                    <Trash_8_Red />
                                                </button>
                                            </div>
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                        ))
                    )}
                    {Object.keys(classAdditions).map((row, index) => (
                        <TableRow
                            onMouseEnter={() => handleListHover(index)}
                            onMouseLeave={handleListUnhover}
                        >
                            <TableCell key={`classNumber${index}`}>
                                <Input
                                    isRequired
                                    id="classNumber"
                                    placeholder="Class #"
                                    type="number"
                                    inputMode="tel"
                                    color="primary"
                                    variant="underlined"
                                    value={classAdditions[index].classNumber}
                                    onChange={(event) => handleUpdateNewRow(index, event.target.id, event.target.value)}
                                    classNames={{ input: "text-center bg-transparent", inputWrapper: ["border-0", "shadow-none"] }}
                                />
                            </TableCell>
                            <TableCell key={`className${index}`}>
                                <Input
                                    isRequired
                                    id="className"
                                    placeholder="Name"
                                    type="text"
                                    color="primary"
                                    variant="underlined"
                                    value={classAdditions[index].className}
                                    onChange={(event) => handleUpdateNewRow(index, event.target.id, event.target.value)}
                                    classNames={{ input: "text-center bg-transparent", inputWrapper: ["border-0", "shadow-none"] }}
                                />
                            </TableCell>
                            <TableCell key={`classDescription${index}`}>
                                <Input
                                    id="classDescription"
                                    placeholder="Description"
                                    type="text"
                                    color="primary"
                                    variant="underlined"
                                    value={classAdditions[index].classDescription}
                                    onChange={(event) => handleUpdateNewRow(index, event.target.id, event.target.value)}
                                    classNames={{ input: "text-center bg-transparent", inputWrapper: ["border-0", "shadow-none"] }}
                                    endContent={ 
                                        <div
                                            className={`
                                                transition-opacity
                                                duration-200
                                                ${
                                                    isHovered === index || isMobile ?
                                                    'opacity-100' :
                                                    'opacity-0'
                                                }
                                            `}>
                                            <button onClick={() => deleteClassListAddition(index)}>
                                                <Trash_8_Red />
                                            </button>
                                        </div>
                                    }
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Spacer y={4} />
            <div>
                <Button
                    onClick={() => {
                        if (classListUpdated) {
                            updateClassList();
                        } else {
                            swal.fire({
                                title: "No changes made",
                                text: "Please make your changes before saving",
                                icon: "error",
                                timer: 1500
                            })
                        }
                    }}
                    color="primary"
                >
                    Save
                </Button>
            </div>
        </div>
    );
}