import React, {
    useEffect,
    useState
} from "react";
import { 
    Form,
    useLocation,
    useNavigate
} from 'react-router-dom';
import Ajax from "../inc/js/Ajax";
import {
    getCurrentDate
} from "../inc/js/Helper"
import {
    Button,
    Input,
    Spacer,
} from "@nextui-org/react";
import swal from "sweetalert2";

export default function ClaimEvent({clientInformation}) {
    const location = useLocation();
    const navigate = useNavigate();
    const salesId = location.state?.salesId || localStorage.getItem('salesId');
    const [sale, setSale] = useState({});
    const [form, setForm] = useState({
        eventName:"",
        eventDate:"",
        rainoutDate:"",
        eventLocation:""
    });
    
    useEffect(() => {
        if (clientInformation?.userInformation?.loggedIn === false) {
            navigate('/Login');
        }
    }, [clientInformation, navigate]);

    useEffect(() => {
        if (!clientInformation?.userInformation?.loggedIn) return;

        if (salesId) {
            Ajax.request({
                url:"/JudgeAutos/readSalesId",
                jsonData:{salesId: salesId},
                success:function(reply) {
                    setSale(reply.data);
                    localStorage.setItem('salesId', reply.data.salesId);
                }
            });
        } else {
            swal.fire({
                title: "Missing SalesId",
                text: "Please try again",
                icon: "error",
                didClose: () => {
                    document.location.href = "/Events?tab=unclaimed"
                }
            })
        }
    }, []);

    const createEvent = () => {
        const currentDate = getCurrentDate();
        const emptyData = Object.keys(form).filter(key => key !== 'rainoutDate').some(key => form[key].trim() === '');

        if (emptyData) {
            swal.fire({
                title: "Missing Fields",
                text: "Please fill in all required fields",
                icon: "error"
            });

            return;
        }

        if (form.eventDate < currentDate) {
            swal.fire({
                title: "Incorrect date",
                text: "Event Date cannot be in the past",
                icon: "error"
            });

            return;
        }

        if (form.rainoutDate && form.rainoutDate < currentDate) {
            swal.fire({
                title: "Incorrect date",
                text: "Rainout Date cannot be in the past",
                icon: "error"
            });

            return;
        }
        
        form.salesId = salesId;

        Ajax.request({
            url:"/JudgeAutos/createEvent",
            jsonData: form,
            success:function(reply) {
                swal.fire({
                    title:`${reply.data.eventName} Created`,
                    text:"You may now setup your Event",
                    icon:"success",
                    didClose: () => {
                        document.location.href = "/Events?tab=" + reply.data.status
                    }
                })
            }
        });
    }

    function handleChange(event) {
        let change = {};
        change[event.target.id] = event.target.value;

        setForm({...form, ...change});
    }

    return(
        <div className="flex-col text-center">
            <h1 className="text-5xl">Claim Event</h1>
            <Spacer y={10} />
            <Form onSubmit={createEvent}>
                <Input
                    isReadOnly
                    label="Purchase Date"
                    value={sale.saleDate}
                    variant="flat"
                />
                <Spacer y={1} />
                <Input
                    isReadOnly
                    label="Package"
                    value={sale.productName}
                    variant="flat"
                />
                <Spacer y={1} />
                <div className="grid grid-cols-2 gap-2 place-content-center">
                    <Input
                        isReadOnly
                        label="Registrations"
                        value={sale.registrationLimit > 999 ? "Unlimited" : sale.registrationLimit}
                        variant="flat"
                    />
                    <Input
                        isReadOnly
                        label="Registrars"
                        value={sale.registrarLimit > 999 ? "Unlimited" : sale.registrarLimit}
                        variant="flat"
                    />
                    <Input
                        isReadOnly
                        label="Judges"
                        value={sale.judgeLimit > 999 ? "Unlimited" : sale.judgeLimit}
                        variant="flat"
                    />
                    <Input
                        isReadOnly
                        label="Classes"
                        value={
                            sale.classLimit === 0 ?
                                "Classes Unavailable" :
                            sale.classLimit > 999 ?
                                "Unlimited" :
                            sale.classLimit
                        }
                        variant="flat"
                    />
                </div>
                <Spacer y={1} />
                <Input
                    isReadOnly
                    label="Forms"
                    value={sale.forms === "custom" ? "Custom Forms" : "Basic Forms"}
                    variant="flat"
                />
                <Spacer y={1} />
                <Input
                    isRequired
                    id="eventName"
                    label="Event Name"
                    onChange={handleChange}
                    variant="bordered"
                />
                <Spacer y={1} />
                <Input
                    isRequired
                    id="eventDate"
                    label="Event Date"
                    onChange={handleChange}
                    type="date"
                    variant="bordered"
                />
                <Spacer y={1} />
                <Input
                    id="rainoutDate"
                    label="Rainout Date"
                    onChange={handleChange}
                    type="date"
                    variant="bordered"
                />
                <Spacer y={1} />
                <Input
                    isRequired
                    id="eventLocation"
                    label="Event Location"
                    onChange={handleChange}
                    variant="bordered"
                />
                <Spacer y={5} />
                <Button 
                    type={"submit"}
                    color="primary"
                    sx={{"margin-top":"1rem"}}
                >
                    Claim Event
                </Button>
            </Form>
        </div>
    );
}