import React, {
    useEffect,
    useState
} from "react";
import {
    useLocation
} from 'react-router-dom';
import {
    Button,
    Dropdown,
    DropdownTrigger,
    DropdownMenu,
    DropdownItem,
    Input,
    Link,
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    Spacer,
    Tab,
    Tabs,
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell
} from "@nextui-org/react";
import swal from "sweetalert2";
import Ajax from "../inc/js/Ajax";
import {
    Trophy_8_Amber_600,
    Trophy_8_Gray_300,
    Trophy_8_Yellow_400
} from "../inc/images/svgs";


export default function Results({clientInformation}) {
    const location = useLocation();
    const [eventId] = useState(location.state?.eventId || localStorage.getItem('eventId'));
    const [missingClasses, setMissingClasses] = useState([]);
    const [resultData, setResultData] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState(new Set());
    const [sortedAllResults, setSortedAllResults] = useState([]);
    const [sortedClassResults, setSortedClassResults] = useState([]);
    const [topScorers, setTopScorers] = useState([]);
    
    const [isMobile, setIsMobile] = useState();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const [resultModalData, setResultModalData] = useState({});
    const [resultModalOpen, setResultModalOpen] = useState(false);
    const [resultModalInput, setResultModalInput] = useState(false);
    const [resultModalInputValues, setResultModalInputValues] = useState({});

    useEffect(() => {
        if (clientInformation.userInformation.loggedIn === false) {
            document.location.href = "/Login";
        } else {
            Ajax.request({
                url:'/JudgeAutos/readResults',
                jsonData: {
                    eventId: eventId
                },
                success:function(reply) {
                    setIsMobile(window.innerWidth < 768)
                    setResultData(reply.data);
                    setSelectedColumns(
                        window.innerWidth < 768
                          ? new Set(["Number", "Name", "Phone Number", "Total Score", "Place"])
                          : new Set(["Place", ...reply.data.registrationFormData.map(item => item.attribute)])
                    );
                    setSortedAllResults(
                        Object.values(reply.data.results).sort((a, b) => a.Number - b.Number)
                    );
                    setTopScorers(() => {
                        const results = reply.data.results;
                        const topScores = Object.values(results).sort((a, b) => b["Total Score"] - a["Total Score"])

                        return topScores;
                    });

                    if (reply.data.classlistdata.length > 0) {
                        const missingClassesSet = new Set();
                        const groupedClassResults = Object.values(reply.data.results).reduce((acc, item, index) => {
                            if (item.Class) {
                                if (!acc[item.Class]) {
                                    acc[item.Class] = [];
                                }
                                acc[item.Class].push(item);
                            } else {
                                missingClassesSet.add(`Registration ${item.Number} \n`);                              
                            }
                            return acc;
                        }, {});

                        setMissingClasses(Array.from(missingClassesSet));

                        setSortedClassResults(
                            Object.keys(groupedClassResults).map(classNumber => {
                                return {
                                    class: classNumber,
                                    className: reply.data.classlistdata.find(name => name.classNumber == classNumber).className,
                                    winners: groupedClassResults[classNumber].sort((a, b) => b["Total Score"] - a["Total Score"])
                                }
                            })
                        )
                    }
                },
                failure:function(reply) {
                    swal.fire({
                        title: "Failed to load result data",
                        text: "Please try again!",
                        icon: "error",
                        didClose: () => {
                            document.location.href = "/Events"
                        }
                    })
                }
            });
        }
    }, [clientInformation]);

    useEffect(() => {
        if (missingClasses !== null && missingClasses.length > 0) {
            swal.fire({
                title: "Registrations missing classes",
                html: missingClasses.join("<br />"),
                icon: "error",
            });
        }
    }, [missingClasses]);

    useEffect(() => {
        const handleResize = () => {
            const newWidth = window.innerWidth;
            const isNowMobile = newWidth < 768;
    
            if (Math.abs(newWidth - screenWidth) > 100) {
                setScreenWidth(newWidth);
                setIsMobile(isNowMobile);
    
                if (resultData?.registrationFormData?.length > 0) {
                    setSelectedColumns(
                        isNowMobile
                            ? new Set(["Number", "Name", "Phone Number", "Total Score", "Place"])
                            : new Set(Object.keys(resultData.registrationFormData))
                    );
                }
            }
        };
    
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [screenWidth]);

    const handleSelectedColumns = (keys) => {
        setSelectedColumns(new Set(keys));
    };

    const openResultsModal = (data) => {
        if (data["Total Score"] <= 0) {
            swal.fire({
                title: "Not Judged",
                text: `Registration ${data.Number} has not been judged yet`,
                icon: "error",
            });
        } else {
            const filterResultData = (data) => {
                const filteredData = Object.entries(data).reduce((acc, [key, value]) => {
                    if (resultData.judgeFormData.some(item => item.attribute.toLowerCase() === key.toLowerCase())) {
                        acc[key] = value;
                    }
                    return acc;
                }, {});
                return filteredData;
            };
            
            setResultModalData(prevData => ({
                ...prevData,
                ...data
            }));
            setResultModalInputValues(Object.fromEntries(Object.entries(filterResultData(data))));
            setResultModalOpen(true);
        }
    }

    const updateJudging = (modalData, inputValues) => {
        Ajax.request({
            url:"/JudgeAutos/updateJudging",
            jsonData: {
                registrationId: modalData["registrationId"],
                judgingData: [inputValues]
            },
            success:function(reply) {
                swal.fire({
                    title: "Success!",
                    text: `Registration ${modalData["Number"]} updated`,
                    icon: "success",
                    timer: 3000,
                    didClose: () => {
                        window.location.reload();
                    }
                });
            }
        });
    }
    
    return(
        <div className="flex-col text-center">
            <Link href="/Event" color="foreground">
                <h1 className="text-5xl">{resultData?.eventData?.eventName} Results</h1>
            </Link>
            <Spacer y={10} />
            <Dropdown
                closeOnSelect={false}
                shouldBlockScroll={false}
            >
                <DropdownTrigger>
                    <Button
                        variant="bordered"
                        color="default"
                    >
                        Columns
                    </Button>
                </DropdownTrigger>
                    <DropdownMenu
                        disallowEmptySelection
                        aria-label="Registration Table Columns Dropdown"
                        selectionMode="multiple"
                        selectedKeys={Array.from(selectedColumns)}
                        onSelectionChange={handleSelectedColumns}                        
                    >
                        {resultData?.registrationFormData?.map((item, index) => (
                            <DropdownItem
                                key={`${item.attribute}`}
                                size="lg"
                                className="p-3"
                            >
                                {item.attribute === "Total Score"
                                    ? "Score"
                                    : item.attribute.split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")
                                }
                            </DropdownItem>
                        ))}
                        <DropdownItem
                            key="Place"
                            size="lg"
                            className="p-3"
                        >
                            Place
                        </DropdownItem>
                        <DropdownItem
                            key="resetColumnSelectionsButton"
                            size="md"
                        >
                            <div className="flex mx-auto justify-center">
                                <Button
                                    variant="solid"
                                    size={isMobile ? "sm" : "md"}
                                    color="primary"
                                    onClick={() =>
                                        setSelectedColumns(new Set(["Number", "Name", "Phone Number", "Total Score", "Place"]))
                                    }
                                >
                                    Reset
                                </Button>
                            </div>
                        </DropdownItem>
                    </DropdownMenu>
            </Dropdown>
            <Spacer y={4} />
            {resultData.registrationFormData && (
                <Tabs>
                    <Tab key="allResults" title="All" aria-label="All results tab" >
                        {Object.keys(resultData.results).length > 0 ? (
                            <Table
                                isHeaderSticky
                                isStriped
                                aria-label="All results table"
                                classNames={{
                                    base: `${isMobile ? "max-h-[520px] overflow-scroll" : ""}`,
                                    table: `${isMobile ? "min-h-[400px]" : ""}`
                                }}
                            >
                                <TableHeader>
                                    {resultData.registrationFormData.filter(
                                        data => data.attribute !== "Class" ||
                                        (data.attribute === "Class" && resultData.classlistdata.length > 0)).map((data) => (
                                            <TableColumn
                                                key={data.attribute}
                                                align="center"
                                                className={`
                                                    ${selectedColumns.has(data.attribute) ? "table-cell text-center" : "hidden"}
                                                    mx-auto
                                                `}
                                            >
                                                {data.attribute === "Total Score"
                                                    ? "Score"
                                                    : data.attribute.split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")
                                                }
                                            </TableColumn>
                                    ))}
                                </TableHeader>
                                <TableBody>
                                    {sortedAllResults.map((data, index) => (
                                        <TableRow
                                            key={index}
                                            style={{cursor: "pointer"}}
                                            onClick={() => openResultsModal(data)}
                                        >
                                            {resultData.registrationFormData.filter(
                                                data => data.attribute !== "Class" ||
                                                (data.attribute === "Class" && resultData.classlistdata.length > 0)
                                            ).map((item) => (
                                                <TableCell
                                                    key={`cell${index}${item.attribute}`}
                                                    className={`
                                                        ${selectedColumns.has(item.attribute) ? "table-cell" : "hidden"}
                                                    `}
                                                >
                                                    {data[item.attribute]}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        ) : (
                            <div>
                                <Spacer y={2} />
                                <p>
                                    No cars have been judged!
                                </p>
                            </div>
                        )}
                    </Tab>
                    {resultData.classlistdata.length > 0 && (
                        <Tab key="byClass" title="By Class" aria-label="Results by class tab" >
                            {sortedClassResults.length > 0 ? (
                                sortedClassResults.map((data, index) => (
                                    <div key={index}>
                                        <h2 className="text-3xl">{data.class}: {data.className}</h2>
                                        <Spacer y={2} />
                                        <Table
                                            isStriped
                                            aria-label={`${data.className} Table`}
                                        >
                                            <TableHeader>
                                                <TableColumn
                                                    className={`
                                                        ${selectedColumns.has("Place") ? "table-cell text-center" : "hidden"}
                                                    `}
                                                >
                                                    Place
                                                </TableColumn>
                                                {Object.keys(data.winners[0]).map((key, keyIndex) => (
                                                    <TableColumn
                                                        key={keyIndex}
                                                        className={`
                                                            ${selectedColumns.has(key) ? "table-cell text-center" : "hidden"}
                                                        `}
                                                    >
                                                        {key}
                                                    </TableColumn>
                                                ))}
                                            </TableHeader>
                                            <TableBody>
                                                {data.winners.map((winner, winnerIndex) => (
                                                    <TableRow
                                                        key={winnerIndex}
                                                    >
                                                        <TableCell
                                                            className={`
                                                                ${selectedColumns.has("Place") ? "table-cell" : "hidden"}
                                                            `}
                                                        >
                                                            {
                                                                winnerIndex === 0 ? <Trophy_8_Yellow_400 />
                                                                : winnerIndex === 1 ? <Trophy_8_Gray_300 />
                                                                : winnerIndex === 2 ? <Trophy_8_Amber_600 />
                                                                : winnerIndex + 1
                                                            }
                                                        </TableCell>
                                                        {Object.keys(winner).map((key, valueIndex) => (
                                                            <TableCell
                                                                key={valueIndex}
                                                                className={`
                                                                    ${selectedColumns.has(key) ? "table-cell" : "hidden"}
                                                                `}
                                                            >
                                                                {winner[key]}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        <Spacer y={5} />
                                    </div>
                                ))
                            ) : (
                                <div>
                                    <Spacer y={2} />
                                    <p>
                                        No cars have been judged!
                                    </p>
                                </div>
                            )}
                        </Tab>
                    )}
                    <Tab key="topScores" title="Top Scores" aria-label="Top Scores Tab">
                        {Object.keys(resultData.results).length > 0 ? (
                            <Table
                                isStriped
                                aria-label="Top Scores Table"
                            >
                                <TableHeader>
                                    <TableColumn
                                        className={`
                                            ${selectedColumns.has("Place") ? "table-cell text-center" : "hidden"}
                                        `}
                                    >
                                        Place
                                    </TableColumn>
                                    {Object.keys(topScorers[0]).map((key, keyIndex) => (
                                        <TableColumn
                                            key={keyIndex}
                                            className={
                                                selectedColumns.has(key)
                                                    ? "table-cell text-center"
                                                    : "hidden"
                                            }
                                        >
                                            {key}
                                        </TableColumn>
                                    ))}
                                </TableHeader>
                                <TableBody>
                                    {topScorers.map((winner, winnerIndex) => (
                                        <TableRow key={winnerIndex}>
                                            <TableCell
                                                className={`
                                                    ${selectedColumns.has("Place") ? "table-cell" : "hidden"}
                                                `}
                                            >
                                                {
                                                    winnerIndex === 0 ? <Trophy_8_Yellow_400 />
                                                    : winnerIndex === 1 ? <Trophy_8_Gray_300 />
                                                    : winnerIndex === 2 ? <Trophy_8_Amber_600 />
                                                    : winnerIndex + 1
                                                }
                                            </TableCell>
                                            {Object.keys(winner).map((key, valueIndex) => (
                                                <TableCell
                                                    key={valueIndex}
                                                    className={
                                                        selectedColumns.has(key)
                                                            ? "table-cell text-center"
                                                            : "hidden"
                                                    }
                                                >
                                                    {winner[key]}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        ) : (
                            <div>
                                <Spacer y={2} />
                                <p>
                                    No cars have been judged!
                                </p>
                            </div>
                        )}
                    </Tab>
                </Tabs>
            )}
            
            <Modal 
                isOpen={resultModalOpen}
                scrollBehavior="outside"
                onClose={() => {
                    setResultModalOpen(false);
                    setResultModalInput(false);
                    setResultModalInputValues({})                            
                }}
                placement="center"
                className="w-11/12"
            >
                <ModalContent>
                    <ModalHeader className="flex justify-center items-center text-center text-3xl">
                        Registration {resultModalData.Number} Results
                    </ModalHeader>
                        <ModalBody>
                            {Object.entries(resultModalInputValues).map(([key, value]) => (
                                <Input
                                    key={key}
                                    id={key}
                                    type="tel"
                                    label={key.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/^./, (str) => str.toUpperCase())}
                                    variant="bordered"
                                    value={resultModalInputValues[key]}
                                    onChange={(event) => {
                                        setResultModalInput(true);
                                        setResultModalInputValues((prevValues) => ({
                                            ...prevValues,
                                            [key]: event.target.value
                                        }));
                                    }}
                                    endContent={`/${resultData["judgeFormData"]?.find(item => item.attribute === key)?.maxScore || ""}`}
                                />
                            ))}
                            <Input
                                isReadOnly
                                key="Total Score"
                                id="Total Score"
                                label="Total Score"
                                variant="bordered"
                                value={Object.entries(resultModalInputValues).reduce((sum, [key, value]) => {
                                    return sum + Number(value);
                                }, 0)}
                                endContent={`/${resultData["judgeFormData"]?.reduce((sum, item) => sum + item.maxScore, 0) || ""}`}
                            />

                            <Button
                                variant="solid"
                                className="w-48 mx-auto"
                                color="primary"
                                size="lg"
                                onClick={() => {
                                    if (resultModalInput) {
                                        if (resultModalData.Number) {
                                            updateJudging(resultModalData, resultModalInputValues)
                                        }
                                    } else {
                                        swal.fire({
                                            title: "No changes made",
                                            text: "Please make your changes before saving",
                                            icon: "error",
                                            timer: 1000
                                        })
                                    }
                                }}
                            >
                                Save
                            </Button>
                        </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    );
}