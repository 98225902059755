import React, {
    useEffect,
    useState
} from "react";
import { 
    useLocation,
    useNavigate
} from 'react-router-dom';
import { 
    Link,
    Spacer,
    Tab,
    Tabs,
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell
} from "@nextui-org/react";
import "../inc/css/loader.css";
import swal from 'sweetalert2';
import Ajax from "../inc/js/Ajax";

export default function Events({clientInformation}) {
    const navigate = useNavigate();
    const location = useLocation();
    const [events, setEvents] = useState([]);
    const [selectedTab, setSelectedTab] = useState("active");

    useEffect(() => {
        if (clientInformation.userInformation.loggedIn === false) {
            document.location.href = "/Login";
        } else {
            const queryParams = new URLSearchParams(window.location.search);

            if (queryParams.get("tab") === "active") {
                setSelectedTab("active");
            }

            if (queryParams.get("tab") === "upcoming") {
                setSelectedTab("upcoming");
            }

            if (queryParams.get("tab") === "completed") {
                setSelectedTab("completed");
            }

            if (queryParams.get("tab") === "unclaimed") {
                setSelectedTab("unclaimed");
            }

            if (queryParams.get("purchase") === "success") {
                swal.fire({
                    title: "Thank you for using JudgeAutos!",
                    html: "You may now create your event. If you need assistance, please view our <a href='/FAQ' style='color: blue; text-decoration: underline;'>FAQ</a> or <a href='/ContactUs' style='color: blue; text-decoration: underline;'>Contact Us</a>.",

                    icon: "success"
                })
            }

            if (window.history.replaceState) {
                window.history.replaceState(null, null, window.location.pathname);
            }
        }
    }, [clientInformation]);

    useEffect(() => {
        Ajax.request({
            url:"/JudgeAutos/readEvents",
            success:function(reply) {
                if(reply.data.reload) {
                    window.location.href = "/Events";
                } else {                    
                    setEvents(reply.data);
                }
            }
        });
    }, []);

    function claimEvent(salesId){
        navigate('/ClaimEvent', {
            state:{salesId}
        });
    }

    function openEvent(eventId, eventName, eventStatus){
        navigate('/Event', {
            state: {
                eventId,
                eventName,
                eventStatus
            } 
        });
    }

    return(
        <div className="flex-col text-center">
            <h1 className="text-5xl">Events</h1>
            <Spacer y={10} />
            <Tabs
                selectedKey={selectedTab}
                onSelectionChange={setSelectedTab}
            >
                <Tab key="active" title="Active">
                    {events.active?.length > 0 ? (
                        <Table 
                            isStriped
                            aria-label="Active events table"
                            onRowAction={
                                (key) => openEvent(
                                    events.active[key].eventId,
                                    events.active[key].eventName,
                                    events.active[key].status
                                )
                            }
                            // onSortChange={list.sort}
                        >
                            <TableHeader>
                                <TableColumn>Event</TableColumn>
                                <TableColumn>Location</TableColumn>
                                <TableColumn className="hidden sm:table-cell">Registrars</TableColumn>
                                <TableColumn className="hidden sm:table-cell">Judges</TableColumn>
                                <TableColumn className="hidden sm:table-cell">Registrations</TableColumn>
                                <TableColumn className="hidden sm:table-cell">Classes</TableColumn>
                                <TableColumn className="hidden sm:table-cell">Forms</TableColumn>
                            </TableHeader>
                            <TableBody>
                                {events.active.map((item, index) => (
                                    <TableRow key={index}  style={{ cursor: 'pointer'}}>
                                        <TableCell>{item.eventName}</TableCell>
                                        <TableCell>{item.eventLocation}</TableCell>
                                        <TableCell className="hidden sm:table-cell">{item.registrarLimit === 999999999 ? "Unlimited": item.registrarLimit}</TableCell>
                                        <TableCell className="hidden sm:table-cell">{item.judgeLimit === 999999999 ? "Unlimited": item.judgeLimit}</TableCell>
                                        <TableCell className="hidden sm:table-cell">{item.registrationLimit === 999999999 ? "Unlimited": item.registrationLimit}</TableCell>
                                        <TableCell className="hidden sm:table-cell">{item.classLimit === 999999999 ? "Unlimited": item.classLimit}</TableCell>
                                        <TableCell className="hidden sm:table-cell">{item.forms.charAt(0).toUpperCase() + item.forms.slice(1)}</TableCell>
                                    </TableRow>
                                ))};
                            </TableBody>
                        </Table>
                    ) : (
                        <p className="text-center">
                            Oh no, you have no Active events!<br />Buy one <Link href="/Pricing">here</Link>!
                        </p>
                    )}
                </Tab>
                <Tab key="upcoming" title="Upcoming">
                    {events.upcoming?.length > 0 ? (
                        <Table 
                        isStriped
                        aria-label="Upcoming events table"
                        onRowAction={
                            (key) => openEvent(
                                events.upcoming[key].eventId,
                                events.upcoming[key].eventName,
                                events.upcoming[key].status
                            )
                        }
                        // onSortChange={list.sort}
                        >
                            <TableHeader>
                                <TableColumn>Event</TableColumn>
                                <TableColumn>Date</TableColumn>
                                <TableColumn>Location</TableColumn>
                                <TableColumn className="hidden sm:table-cell">Registrars</TableColumn>
                                <TableColumn className="hidden sm:table-cell">Judges</TableColumn>
                                <TableColumn className="hidden sm:table-cell">Registrations</TableColumn>
                                <TableColumn className="hidden sm:table-cell">Classes</TableColumn>
                                <TableColumn className="hidden sm:table-cell">Forms</TableColumn>
                            </TableHeader>
                            <TableBody>
                                {events.upcoming.map((item, index) => (
                                    <TableRow key={index} style={{ cursor: 'pointer'}}>
                                        <TableCell>{item.eventName}</TableCell>
                                        <TableCell>{item.eventDate}</TableCell>
                                        <TableCell>{item.eventLocation}</TableCell>
                                        <TableCell className="hidden sm:table-cell">{item.registrarLimit === 999999999 ? "Unlimited": item.registrarLimit}</TableCell>
                                        <TableCell className="hidden sm:table-cell">{item.judgeLimit === 999999999 ? "Unlimited": item.judgeLimit}</TableCell>
                                        <TableCell className="hidden sm:table-cell">{item.registrationLimit === 999999999 ? "Unlimited": item.registrationLimit}</TableCell>
                                        <TableCell className="hidden sm:table-cell">{item.classLimit === 999999999 ? "Unlimited": item.classLimit}</TableCell>
                                        <TableCell className="hidden sm:table-cell">{item.forms.charAt(0).toUpperCase() + item.forms.slice(1)}</TableCell>
                                    </TableRow>
                                ))};
                            </TableBody>
                        </Table>
                    ) : (
                        <p className="text-center">
                            Oh no, you have no events!<br />Buy one <Link href="/Pricing">here</Link>!
                        </p>
                    )}
                </Tab>
                <Tab key="completed" title="Completed">
                    {events.completed?.length > 0 ? (
                        <Table 
                        isStriped
                        aria-label="Completed events table"
                        onRowAction={
                            (key) => openEvent(
                                events.completed[key].eventId,
                                events.completed[key].eventName,
                                events.completed[key].status
                            )
                        }
                        // onSortChange={list.sort}
                        >
                            <TableHeader>
                                <TableColumn>Event</TableColumn>
                                <TableColumn>Event Date</TableColumn>
                                <TableColumn>Location</TableColumn>
                                <TableColumn className="hidden sm:table-cell">Package</TableColumn>
                                <TableColumn className="hidden sm:table-cell">Sale Date</TableColumn>
                            </TableHeader>
                            <TableBody>
                                {events.completed.map((item, index) => (
                                    <TableRow key={index} style={{ cursor: 'pointer'}}>
                                        <TableCell>{item.eventName}</TableCell>
                                        <TableCell>{item.eventDate}</TableCell>
                                        <TableCell>{item.eventLocation}</TableCell>
                                        <TableCell className="hidden sm:table-cell">{item.productName}</TableCell>
                                        <TableCell className="hidden sm:table-cell">{item.saleDate}</TableCell>
                                    </TableRow>
                                ))};
                            </TableBody>
                        </Table>
                    ) : (
                        <p className="text-center">
                            Oh no, you have not completed any events!<br />Buy one <Link href="/Pricing">here</Link>!
                        </p>
                    )}
                </Tab>
                <Tab key="unclaimed" title="Unclaimed">
                    {events.unclaimed?.length > 0 ? (
                        <Table 
                        isStriped
                        aria-label="Unclaimed events table"
                        onRowAction={(key) => claimEvent(events.unclaimed[key].salesId)}
                        // onSortChange={list.sort}
                        >
                            <TableHeader>
                                <TableColumn>Purchase Date</TableColumn>
                                <TableColumn>Package</TableColumn>
                                <TableColumn className="hidden sm:table-cell">Registrars</TableColumn>
                                <TableColumn className="hidden sm:table-cell">Judges</TableColumn>
                                <TableColumn className="hidden sm:table-cell">Registrations </TableColumn>
                                <TableColumn className="hidden sm:table-cell">Classes</TableColumn>
                                <TableColumn className="hidden sm:table-cell">Forms</TableColumn>
                            </TableHeader>
                            <TableBody>
                                {events.unclaimed.map((item, index) => (
                                    <TableRow key={index} style={{ cursor: 'pointer'}}>
                                        <TableCell>{item.saleDate}</TableCell>
                                        <TableCell>{item.productName}</TableCell>
                                        <TableCell className="hidden sm:table-cell">{item.registrarLimit === 999999999 ? "Unlimited": item.registrarLimit}</TableCell>
                                        <TableCell className="hidden sm:table-cell">{item.judgeLimit === 999999999 ? "Unlimited": item.judgeLimit}</TableCell>
                                        <TableCell className="hidden sm:table-cell">{item.registrationLimit === 999999999 ? "Unlimited": item.registrationLimit}</TableCell>
                                        <TableCell className="hidden sm:table-cell">{item.classLimit === 999999999 ? "Unlimited": item.classLimit}</TableCell>
                                        <TableCell className="hidden sm:table-cell">{item.forms.charAt(0).toUpperCase() + item.forms.slice(1)}</TableCell>
                                    </TableRow>
                                ))};
                            </TableBody>
                        </Table>
                    ) : (
                        <p className="text-center">
                            Oh no, you have no available events.<br />Buy one <Link href="/Pricing">here</Link>!
                        </p>
                    )}
                </Tab>
            </Tabs>
        </div>
    );
}