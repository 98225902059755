import React, {
    useEffect,
    useState
  } from "react";
  import {
    createBrowserRouter,
    RouterProvider
  } from "react-router-dom";
  import "./inc/css/loader.css";
  import Ajax from "./inc/js/Ajax";
  import {
    Spacer,
    Spinner
  } from "@nextui-org/react";
  
  import ContactUs from './components/ContactUs';
  import CreateAccount from './components/CreateAccount';
  import ClaimEvent from './components/ClaimEvent';
  import Error404 from "./components/Error404";
  import Event from './components/Event';
  import Events from './components/Events';
  import FAQ from "./components/FAQ";
  import Footer from "./components/Footer";
  import ForgotPassword from './components/ForgotPassword';
  import Forms from "./components/Forms";
  import GettingStarted from "./components/GettingStarted";
  import Header from './components/Header';
  import Home from './components/Home';
  import JudgeForm from './components/JudgeForm';
  import Judging from './components/Judging';
  import ClassList from './components/ClassList';
  import Login from './components/Login';
  import MyAccount from './components/MyAccount';
  import Pricing from './components/Pricing';
  import PrivacyPolicy from './components/PrivacyPolicy';
  import Purchases from './components/Purchases';
  import Registration from './components/Registration';
  import RegistrationForm from './components/RegistrationForm';
  import ResetPassword from './components/ResetPassword';
  import Results from './components/Results';
  
    export default function App() {
        const userInformation = "";
        const [clientInformation, setClientInformation] = useState({
            userInformation: {},
            ipAddress: "UNKNOWN",
        });
  
        useEffect(() => {
            Ajax.request({
                url:"/JudgeAutos/checkIfLoggedIn",
                method:"GET",
                success:function(reply) {
                    setClientInformation((prev) => ({
                        ...prev,
                        userInformation: reply.data.userInformation,
                    }));            
                }
            });

            fetch("https://api.ipify.org?format=json").then((response) => response.json()).then((data) => {
                setClientInformation((prev) => ({
                    ...prev,
                    ipAddress: data.ip,
                }));
            }).catch(() => {
                setClientInformation((prev) => ({
                    ...prev,
                    ipAddress: "UNKNOWN",
                }));
            });
        }, []);
    
      const router = createBrowserRouter([
          {
              path: "/",
              element: <Home />,
              errorElement: <Error404 />,
          },
          {
              path: "/ContactUs",
              element: <ContactUs clientInformation={clientInformation} />,
          },
          {
              path: "/CreateAccount",
              element: <CreateAccount clientInformation={clientInformation} />,
          },
          {
              path: "/ClaimEvent",
              element: <ClaimEvent clientInformation={clientInformation} />,
          },
          {
              path: "/Event",
              element: <Event clientInformation={clientInformation}/>,
          },
          {
              path: "/Events",
              element: <Events clientInformation={clientInformation} />,
          },
          {
              path: "/FAQ",
              element: <FAQ />,
          },
          {
              path: "/Footer",
              element: <Footer />,
          },
          {
              path: "/Forms",
              element: <Forms clientInformation={clientInformation} />,
          },
          {
              path: "/GettingStarted",
              element: <GettingStarted />,
          },
          {
              path: "/JudgeForm",
              element: <JudgeForm clientInformation={clientInformation} />,
          },
          {
              path: "/Judging",
              element: <Judging />,
          },
          {
              path: "/ClassList",
              element: <ClassList clientInformation={clientInformation} />,
          },
          {
              path: "/Login",
              element: <Login clientInformation={clientInformation} />,
          },
          {
              path: "/MyAccount",
              element: <MyAccount clientInformation={clientInformation} />,
          },
          {
              path: "/Pricing",
              element: <Pricing clientInformation={clientInformation} />,
          },
          {
              path: "/PrivacyPolicy",
              element: <PrivacyPolicy clientInformation={clientInformation} />,
          },
          {
              path: "/Purchases",
              element: <Purchases clientInformation={clientInformation} />,
          },
          {
              path: "/Registration",
              element: <Registration />,
          },
          {
              path: "/RegistrationForm",
              element: <RegistrationForm clientInformation={clientInformation} />,
          },
          {
              path: "/ResetPassword",
              element: <ResetPassword />,
          },
          {
              path: "/Results",
              element: <Results clientInformation={clientInformation} />,
          },
          {
              path: "/ForgotPassword",
              element: <ForgotPassword clientInformation={clientInformation} />,
          },
      ]);
  
      return (
          <div className="flex flex-col min-h-screen">
              <div className="flex flex-grow">
                  <div className="hidden sm:block sm:w-1/5 sm:bg-gray-100"></div>
                  <div className="flex-grow flex flex-col min-h-screen">
                      <Header clientInformation={clientInformation} />
                      <div id="loading" className="flex-shrink-0">
                          <div id="loading-image">
                              <Spinner />
                          </div>
                      </div>
                      <Spacer y={10} />
                      <div className="flex-grow flex mx-auto justify-center w-[98%] sm:w-3/4 mb-10">
                          <RouterProvider router={router} />
                      </div>
                      <div className="w-full sm:w-11/12 mx-auto mt-auto">
                          <Footer />
                      </div>
                  </div>
                  <div className="hidden sm:block sm:w-1/5 sm:bg-gray-100"></div>
              </div>
          </div>
      );
    
  
  //   return (
  //     <main className="light flex min-h-screen bg-white-800">
  //         <div className="invisible sm:visible sm:w-1/5 sm:bg-gray-100 sm:min-h-screen"></div>
  //             <div className="flex-grow min-h-screen flex flex-col">
  //                 <Header userInformation={userInformation} />
  //                 <div id="loading" className="flex-shrink-0">
  //                     <div id="loading-image">
  //                     <Spinner />
  //                     </div>
  //                 </div>
  //                 <Spacer y={10} />
  //                 <div className="flex mx-auto justify-center w-3/4 mb-10">
  //                     <RouterProvider router={router} />
  //                 </div>
  //                 <div className="w-11/12 mx-auto">
  //                     <Footer />
  //                 </div>
  //             </div>
  //         <div className="invisible sm:visible sm:w-1/5 sm:bg-gray-100 sm:min-h-screen"></div>
  //     </main>
  //   );
  }