import React, {
    useEffect,
    useState
} from "react";
import { 
    Button,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    Spacer,
    Table,
    TableBody,
    TableCell,
    TableColumn,
    TableHeader,
    TableRow,
} from "@nextui-org/react";
import "../inc/css/loader.css";
import Ajax from "../inc/js/Ajax";


export default function Login({clientInformation}) {
    useEffect(() => {
        if(clientInformation.userInformation.loggedIn === false) {
            document.location.href = "/";
        } else {
            Ajax.request({
                url:"/JudgeAutos/readPurchases",
                success:function(reply) {
                    if(reply.data) {
                        setPurchases(reply.data);
                    }
                }
            });
        }
    }, [clientInformation]);

    const [purchases, setPurchases] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState([]);

    return(
        <div className="flex-col text-center">
            <h1 className="text-5xl">Purchases</h1>
            <Spacer y={10} />
            <Table isStriped aria-label="Purchases table">
                <TableHeader>
                    <TableColumn className="text-center">Purchased</TableColumn>
                    <TableColumn className="text-center">Product</TableColumn>
                    <TableColumn className="text-center">Price</TableColumn>
                    <TableColumn className="text-center hidden sm:table-cell">Claimed</TableColumn>
                    <TableColumn className="text-center hidden sm:table-cell">Event</TableColumn>
                    <TableColumn className="text-center hidden sm:table-cell">Sales Id</TableColumn>
                </TableHeader>
                <TableBody>
                    {purchases.map((item, index) => (
                        <TableRow
                            key={index}
                            style={{ cursor: 'pointer'}}
                            onClick={() => {
                                setModalOpen(true);
                                setModalData(purchases[index]);
                            }}
                        >
                            <TableCell>{item.saleDate}</TableCell>
                            <TableCell>{item.productName}</TableCell>
                            <TableCell>{`$${item.saleAmount}`}</TableCell>
                            <TableCell className="hidden sm:table-cell">{item.fulfilledDate}</TableCell>
                            <TableCell className="hidden sm:table-cell">{item.eventName}</TableCell>
                            <TableCell className="hidden sm:table-cell">{item.salesId}</TableCell>
                        </TableRow>
                    ))};
                </TableBody>
            </Table>

            <Modal 
                isOpen={modalOpen}
                scrollBehavior="outside"
                onClose={() => {
                    setModalOpen(false);
                }}
                placement="center"
                className="w-11/12"
            >
                <ModalContent>
                    <ModalHeader className="w-full flex justify-center">
                        <h1 className="text-center text-3xl">{modalData.eventName}</h1>
                    </ModalHeader>
                    <ModalBody className="m-2">
                        <div className="flex flex-wrap">
                            {modalData &&
                                Object.entries(modalData).filter(
                                    ([key]) => key !== "eventName" && key !== "stripeReceiptUrl"
                                ).map(([key, value]) => (
                                    <div key={key} className={`
                                        ${["saleDate", "productName", "saleAmount", "fulfilledDate", "salesId"].includes(key) 
                                            ? 'w-full' 
                                            : 'w-1/2'
                                        } 
                                        px-2 box-border
                                    `}>
                                        <Input
                                            id={key}
                                            label={
                                                key === "saleDate"
                                                ? "Purchase Date"
                                                : key === "fulfilledDate"
                                                ? "Claimed Date"
                                                : key.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/^./, (str) => str.toUpperCase())
                                            }
                                            value={
                                                key === "saleAmount"
                                                ? `$${value}`
                                                : key.toLowerCase().includes("limit") && value > 999
                                                ? "Unlimited"
                                                : value
                                            }
                                            variant="flat"
                                            color="default"
                                            isReadOnly
                                            className="w-full m-1"
                                        />
                                    </div>
                                ))
                            }
                            <Button 
                                className={modalData["stripeReceiptUrl"] ? "mx-auto my-2" : "invisible"}
                                variant="solid"
                                color="primary"
                                onClick={() => {
                                    window.open(modalData["stripeReceiptUrl"], '_blank');
                                }}
                            >
                                View Receipt
                            </Button>
                        </div>
                    </ModalBody>
                    {/* <ModalBody className="m-2">
                        <div className="flex flex-wrap">
                            {modalData &&
                                Object.entries(modalData).filter(
                                    ([key]) => key !== "eventName" && !(key.toLowerCase().includes("limit") || key.toLowerCase().includes("forms"))
                                ).map(([key, value]) => (
                                    <div key={key} className={`
                                        ${["saleDate", "productName", "saleAmount", "fulfilledDate", "salesId"].includes(key) 
                                            ? 'w-full' 
                                            : 'w-1/2'
                                        }
                                        
                                    `}>
                                        <Input
                                            id={key}
                                            label={
                                                key === "saleDate"
                                                ? "Purchase Date"
                                                : key === "fulfilledDate"
                                                ? "Claimed Date"
                                                : key.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/^./, (str) => str.toUpperCase())
                                            }
                                            value={
                                                key === "saleAmount"
                                                ? `$${value}`
                                                : key.toLowerCase().includes("limit") && value > 999
                                                ? "Unlimited"
                                                : value
                                            }
                                            variant="flat"
                                            isReadOnly
                                            className="w-full m-1"
                                        />
                                        {key === "productName" &&
                                            <Textarea
                                                isDisabled
                                                defaultValue="5 registrars, 5 judges, no classes, 100 registrations, and basic forms"
                                                label="Product description"
                                                variant="flat"
                                            />
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </ModalBody> */}
                </ModalContent>
            </Modal>
        </div>
    )
}