import React, {useEffect, useState} from "react";
import {
    Avatar,
    Button,
    Spacer,
    Input,
} from "@nextui-org/react";
import Ajax from "../inc/js/Ajax";
import swal from "sweetalert2";

export default function MyAccount({clientInformation}) {
    useEffect(() => {
        console.log(clientInformation);
        if (clientInformation.userInformation.loggedIn === true) {
            setForm(clientInformation.userInformation);
        } else if (clientInformation.userInformation.loggedIn === false) {
            document.location.href = "/Login";
        }
    }, [clientInformation]);

    const [updated, setUpdated] = useState(false);
    const [form, setForm] = useState({
        email:"",
        firstName:"",
        lastName:"",
        organization:"",
        supportPhone:"",
        supportEmail:"",
        website:"",
        plan:""
    });
    
    function handleChange(event) {
        let change = {};
        change[event.target.id] = event.target.value;
        setUpdated(true);

        setForm({...form, ...change});
    }

    function updateProfile() {
        Ajax.request({
            url:"/JudgeAutos/updateProfile",
            jsonData:form,
            success:function(reply) {
                swal.fire({
                    title:"Success!",
                    text:"Profile Updated",
                    icon:"success",
                    timer: 1500,
                    didClose: () => {
                        setUpdated(false);
                    }
                });
            }
        });
    }

    return (
        <div className="flex flex-col gap-10 text-center">
            <h1 className="text-5xl">My Account</h1>
            <div className="mx-auto">
                {clientInformation.userInformation.avatar ? (
                    <Avatar src={clientInformation.userInformation.avatar}
                    className="w-40 h-40" 
                    />
                ) : (
                    <Avatar name={
                        `${clientInformation.userInformation.firstName}
                        ${clientInformation.userInformation.lastName}`}
                        className="w-40 h-40 text-large"
                        returnName = {(name) =>
                            name
                        }
                    />
                )}
            </div>
            <div className="grid grid-cols-2 gap-5 sm:gap-10 place-content-center">
                <div>       
                    <Input
                        id="firstName"                        
                        label="First Name"
                        variant="bordered"
                        value={form.firstName}
                        onChange={handleChange}
                        sx={{mb:"1rem"}}
                        fullWidth={window.innerWidth < 760}
                    />
                    <Spacer y={2} />
                    <Input
                        id="lastName"                        
                        label="Last Name"
                        variant="bordered"
                        value={form.lastName}
                        onChange={handleChange}
                        sx={{mb:"1rem"}}
                        fullWidth={window.innerWidth < 760}
                    />
                    <Spacer y={2} />
                    <Input
                        isReadOnly
                        id="email"                        
                        label="Email"
                        variant="solid"
                        value={form.email}
                        sx={{mb:"1rem"}}
                        fullWidth={window.innerWidth < 760}
                    />
                    <Spacer y={2} />
                </div>
                <div>
                    <Input
                        id="organization"                        
                        label="Organization Name"
                        variant="bordered"
                        value={form.organization}
                        onChange={handleChange}
                        sx={{mb:"1rem"}}
                        fullWidth={window.innerWidth < 760}
                    />
                    <Spacer y={2} />
                    <Input
                        id="supportPhone"                        
                        label="Phone Number"
                        variant="bordered"
                        value={form.phoneNumber}
                        onChange={handleChange}
                        sx={{mb:"1rem"}}
                        fullWidth={window.innerWidth < 760}
                    />
                    <Spacer y={2} />
                    <Input
                        id="website"                        
                        label="Website"
                        variant="bordered"
                        value={form.website}
                        onChange={handleChange}
                        sx={{mb:"1rem"}}
                        fullWidth={window.innerWidth < 760}
                    />
                </div>
            </div>
            <div className="grid grid-cols-3 gap-3 place-content-center">
                <Button
                onClick={() => document.location.href = "/Events"}
                color= "primary"
                >
                    Events
                </Button>
                <Button
                onClick={() => document.location.href = "/Forms"}
                color= "primary"
                >
                    Forms
                </Button>
                <Button
                onClick={() => document.location.href = "/Purchases"}
                color= "primary"
                >
                    Purchases
                </Button>
            </div>
            {updated && (
                <Button onClick={updateProfile} className="mx-auto" color="primary">Save Changes</Button>
            )}
        </div>
    );
}