import React, {
    useEffect,
    useMemo,
    useState
} from "react";
import { 
    Form
} from 'react-router-dom';
import {
    Button,
    Dropdown,
    DropdownTrigger,
    DropdownMenu,
    DropdownItem,
    Input,
    Link,
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    Spacer,
    Tab,
    Tabs,
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell
} from "@nextui-org/react";
import swal from "sweetalert2";
import Ajax from "../inc/js/Ajax";
import {
    Trash_8_Red
} from "../inc/images/svgs";

export default function Registration() {
    const [registrationInfo, setRegistrationInfo] = useState([]);    
    const [formData, setFormData] = useState({});
    const [registrations, setRegistrations] = useState({});

    const [registrationModalData, setRegistrationModalData] = useState({});
    const [registrationModalInput, setRegistrationModalInput] = useState(false);
    const [registrationModalInputValues, setRegistrationModalInputValues] = useState({});
    const [registrationModalOpen, setRegistrationModalOpen] = useState(false);

    const [tabSelection, setTabSelection] = useState("register");
    const [selectedRegistrationColumns, setSelectedRegistrationColumns] = useState(new Set(["Number", "Name"]));

    useEffect(() => {
        Ajax.request({
            url:'/JudgeAutos/loadRegistration',
            success:function(reply) {
                if(reply.data) {
                    setRegistrationInfo(reply.data);                    
                    setFormData(({
                        Number: "number"
                    }));

                    if (reply.data.registrarInfo.admin === 1) {
                        setRegistrations(reply.data.registrations);
                    }
                }
            },
            failure:function(reply) {
                swal.fire({
                    title: "Failed to load registration",
                    text: reply,
                    icon: "error",
                    didClose: () => {
                        document.location.href = "/"
                    }
                })
            }
        });
    }, []);

    const addRegistration = () => {
        if (!formData.Number) {
            formData.Number = "number";
        }

        if (Object.keys(formData).length === registrationInfo.registrationFormData.length) {
            if (formData["Phone Number"].replace(/-/g, '').length === 10) {
                Ajax.request({
                    url:'/JudgeAutos/addRegistration',
                    jsonData: {
                        formData: [formData]
                    },
                    success:function(reply) {
                        if(reply.data) {
                            swal.fire({
                                title: "Success",
                                text: `Successfully registered as ${reply.data}`,
                                icon: "success",
                                didClose: () => {
                                    window.location.reload()
                                }
                            })
                        }
                    }
                });
            } else {
                swal.fire({
                    title: "Invalid Phone Number",
                    text: "Please enter a 10 digit phone number!",
                    icon: "error",
                    timer: 1500
                })
                return;
            }
        } else {
            swal.fire({
                title: "Missing value",
                text: "Please fill in all fields!",
                icon: "error",
                timer: 1500
            })
            return;
        }
    };

    const deleteRegistration = (registrationId, number) => {
        Ajax.request({
            url:"/JudgeAutos/deleteRegistration",
            jsonData: {
                registrationId: registrationId
            },
            success:function(reply) {
                swal.fire({
                    title: "Success!",
                    text: `${reply.data} ${number}`,
                    icon: "success",
                    timer: 3000,
                    didClose: () => {
                        window.location.reload();
                    }
                });
            }
        });
    }

    const updateRegistration = (registrationId, registrationData) => {
        Ajax.request({
            url:"/JudgeAutos/updateRegistration",
            jsonData: {
                registrationId: registrationId,
                registrationData: [registrationData]
            },
            success:function(reply) {
                swal.fire({
                    title: "Success!",
                    text: `Registration ${reply.data} updated`,
                    icon: "success",
                    timer: 3000,
                    didClose: () => {
                        window.location.reload();
                    }
                });
            }
        });
    }

    const handleSelectedRegistrationColumns = (keys) => {
        setSelectedRegistrationColumns(new Set(keys));
    };

    const handleInputChange = (attribute, value) => {
        if (/^[\d-]+$/.test(value)) {
            value = value.replace(/-/g, '');
        }
        
        setFormData(prevState => {
            let newForm = {
                ...prevState
            };

            newForm[attribute] = value;
            return newForm;
        });
    };

    const openRegistrationModal = (registrationId, registrationData) => {
        setRegistrationModalData({
            registrationId: registrationId,
            ...Object.fromEntries(Object.entries(registrationData))
        });
        setRegistrationModalInputValues(Object.fromEntries(Object.entries(registrationData)));
        setRegistrationModalOpen(true);
    }

    const Registrations = () => (
        <Table isStriped aria-label="Registrations Table">
            <TableHeader>
                {Object.keys(registrations[Object.keys(registrations)[0]]).map((attribute) => (
                    <TableColumn
                        key={attribute}
                        align="center"
                        className={
                            selectedRegistrationColumns.has(attribute)
                                ? "table-cell text-center"
                                : "hidden"
                        }
                    >
                        {attribute.split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")}
                    </TableColumn>
                ))}
            </TableHeader>
            <TableBody>
                {Object.keys(registrations).map((key, index) => (
                    <TableRow
                        key={key}
                        style={{cursor: "pointer"}}
                        onClick={() => openRegistrationModal(key, registrations[key])}
                    >
                        {Object.entries(registrations[key]).map(([attribute, value]) => (
                            <TableCell
                                key={`${key}-${attribute}`}
                                className={
                                    selectedRegistrationColumns.has(attribute)
                                        ? "table-cell text-center"
                                        : "hidden"
                                }
                            >
                                {value}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )

    return(
        <div className="flex-col text-center">
            <Link href="/Registration" color="foreground">
                <h1 className="text-5xl">Registration</h1>
            </Link >
            <Spacer y={4} />
            {registrationInfo.registrarInfo ? (
                <p className="text-xl">Welcome {registrationInfo.registrarInfo.name}!</p>
            ):(
                <p className="text-xl">Welcome!</p>
            )}
            <Spacer y={2} />
            {tabSelection === "register" ?
                <p>Please fill in the required<span style={{color: "red"}}>*</span> fields</p> :
                <p>Press a Registration to edit</p>
            }
            <Spacer y={6} />
            {registrationInfo.registrarInfo && (
                registrationInfo.registrarInfo.admin === 1 ? (
                    <Tabs selectedKey={tabSelection} onSelectionChange={setTabSelection}>
                        <Tab key="register" title="Register" aria-label="Register tab">
                            <Form key="register">
                                {registrationInfo.registrationFormData.filter(data =>
                                    (data.attribute !== "Class" || registrationInfo.registrarInfo.classLimit !== 0)
                                ).map((data, index) =>(
                                    <React.Fragment key={index}>
                                        <Input
                                            isRequired={data.attribute === "Number" ? false : true}
                                            label={data.attribute}
                                            value={formData[data.attribute]}
                                            variant="bordered"
                                            type={
                                                /email/i.test(data.attribute) ? 'email' :
                                                /number|year|class|phone/i.test(data.attribute) ? 'number' :
                                                /date/i.test(data.attribute) ? 'date' :
                                                'text'
                                            }  
                                            inputMode={
                                                /number|year|class|phone/i.test(data.attribute) ? 'numeric' :
                                                undefined
                                            }
                                            onChange={(e) => handleInputChange(data.attribute, e.target.value)}
                                        />
                                        {index !== registrationInfo.registrationFormData.length - 1 && <Spacer y={1} />}
                                    </React.Fragment>
                                ))}
                                <Spacer y={2} />
                                <Button
                                    onClick={addRegistration}
                                    color="primary"
                                >
                                    Add
                                </Button>
                            </Form>
                        </Tab>
                        <Tab key="registrations" title="Registrations" aria-label="Registrations tab">
                            
                            {Object.keys(registrations).length > 0 ?
                                <div >
                                    <Dropdown closeOnSelect={false} shouldBlockScroll={false}>
                                        <DropdownTrigger>
                                            <Button
                                                variant="bordered"
                                                color="default"
                                            >
                                                Columns
                                            </Button>
                                        </DropdownTrigger>
                                        <DropdownMenu
                                            disallowEmptySelection
                                            aria-label="Registration Table Columns Dropdown"
                                            selectionMode="multiple"
                                            selectedKeys={Array.from(selectedRegistrationColumns)}
                                            onSelectionChange={handleSelectedRegistrationColumns}
                                        >
                                            {Object.keys(registrations[Object.keys(registrations)[0]]).map((attribute) => (
                                                <DropdownItem key={`${attribute}`}>
                                                    {attribute.split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")}
                                                </DropdownItem>
                                            ))}
                                            <DropdownItem 
                                                key="resetColumnSelectionsButton"
                                                onClick={(e) => {
                                                    setSelectedRegistrationColumns(new Set(["Number", "Name"]))
                                                }}
                                            >
                                                <div className="flex mx-auto justify-center">
                                                    <Button
                                                        variant="solid"
                                                        color="primary"
                                                        onClick={() => setSelectedRegistrationColumns(new Set(["Number", "Name"]))}
                                                    >
                                                        Reset
                                                    </Button>
                                                </div>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                    <Registrations />
                                </div> : 
                                <>
                                    <div>
                                        <Spacer y={5} />
                                        <p>No registrations just yet!</p>
                                        <Spacer y={5} />
                                        <Button
                                            onClick={() => window.location.reload()}
                                            color="primary"
                                        >
                                            Refresh
                                        </Button>
                                    </div>
                                </>
                            }
                        </Tab>
                    </Tabs>
                ) : (
                    <div>
                        <Form key="register">
                            {registrationInfo.registrationFormData.filter(data =>
                                (data.attribute !== "Class" || registrationInfo.registrarInfo.classLimit !== 0)
                            ).map((data, index) =>(
                                <React.Fragment key={index}>
                                    <Input
                                        isRequired={data.attribute === "Number" ? false : true}
                                        label={data.attribute}
                                        value={formData[data.attribute]}
                                        variant="bordered"
                                        type={
                                            /email/i.test(data.attribute) ? 'email' :
                                            /number|year|class|phone/i.test(data.attribute) ? 'number' :
                                            /date/i.test(data.attribute) ? 'date' :
                                            'text'
                                        }  
                                        inputMode={
                                            /number|year|class|phone/i.test(data.attribute) ? 'numeric' :
                                            undefined
                                        }
                                        onChange={(e) => handleInputChange(data.attribute, e.target.value)}
                                    />
                                    {index !== registrationInfo.registrationFormData.length - 1 && <Spacer y={1} />}
                                </React.Fragment>
                            ))}
                            <Spacer y={2} />
                            <Button
                                onClick={addRegistration}
                                color="primary"
                            >
                                Add
                            </Button>
                        </Form>
                    </div>
                )
            )}

            <Modal
                isOpen={registrationModalOpen}
                scrollBehavior="outside"
                onClose={() => {
                    setRegistrationModalOpen(false);
                    setRegistrationModalInput(false);
                    setRegistrationModalInputValues({})                            
                }}
                placement="center"
                className="w-11/12"
            >
                <ModalContent>
                    <ModalHeader className="flex justify-center items-center text-center text-3xl">
                        Registration {registrationModalData.Number}
                    </ModalHeader>
                        <ModalBody>
                            {Object.entries(registrationModalData).filter(
                                    ([key]) => !["number", "registrationid"].includes(key.toLowerCase())).map(([key, value]) => (
                                <Input
                                    key={key}
                                    id={key}
                                    type={
                                        ["class", "phone number", "year"].includes(key.toLowerCase()) 
                                        ? "tel" 
                                        : key.toLowerCase() === "email" 
                                        ? "email" 
                                        : "text"
                                    }
                                    label={key.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/^./, (str) => str.toUpperCase())}
                                    variant="bordered"
                                    value={registrationModalInputValues[key]}
                                    onChange={(event) => {
                                        setRegistrationModalInput(true)
                                        setRegistrationModalInputValues((prevValues) => ({
                                            ...prevValues,
                                            [key]: event.target.value
                                        }))
                                    }}
                                    endContent={key.toLowerCase() === "name" && (
                                        <Button
                                            variant="light"
                                            onClick={() => {
                                                swal.fire({
                                                    title: "Confirm Deletion",
                                                    text: `Delete Registration ${registrationModalData.Number}?`,
                                                    icon: "warning",
                                                    showConfirmButton: true,
                                                    confirmButtonText: "Delete",
                                                    showCancelButton: true,
                                                }).then((result) => {
                                                    if (result.isConfirmed) {
                                                        deleteRegistration(registrationModalData.registrationId, registrationModalData.Number);
                                                    }
                                                });
                                            }}
                                        >
                                            <Trash_8_Red />
                                        </Button>
                                    )}
                                />
                            ))}
                            <Button
                                variant="solid"
                                className="w-48 mx-auto"
                                color="primary"
                                size="lg"
                                onClick={() => {
                                    if (registrationModalInput) {
                                        if (registrationModalData.Number) {
                                            updateRegistration(registrationModalData.registrationId, registrationModalInputValues)
                                        }
                                    } else {
                                        swal.fire({
                                            title: "No changes made",
                                            text: "Please make your changes before saving",
                                            icon: "error",
                                            timer: 1500
                                        })
                                    }
                                }}
                            >
                                Save
                            </Button>
                        </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    );
}