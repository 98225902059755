import React, {
    useEffect,
    useState
} from "react";
import Ajax from "../inc/js/Ajax";
import swal from "sweetalert2";
import {Form} from "react-router-dom";
import { 
    Button,
    Input,
    Link,
    Spacer
} from "@nextui-org/react";

export default function CreateAccount({clientInformation}) {
    useEffect(() => {
        if (clientInformation.userInformation.loggedIn === true) {
            document.location.href = "/MyAccount";
        }
    }, [clientInformation]);
    
    const [isVisible, setIsVisible] = useState(false);
    const toggleVisibility = () => setIsVisible(!isVisible);
    const [isPasswordValid, setIsPasswordValid] = useState(true);

    function createAccount() {
        const formData = {
            firstName:document.getElementById("firstName").value,
            lastName:document.getElementById("lastName").value,
            email:document.getElementById("email").value,
            phone:document.getElementById("phone").value,
            password:document.getElementById("password").value,
            organization:document.getElementById("organization").value,
            ipAddress: clientInformation.ipAddress || 'UNKNOWN'
        };

        const isPasswordValid = formData.password.length >= 9;

        if(!(formData["phone"].replace(/-/g, '').length === 10)) {
            swal.fire({
                title: "Invalid Phone Number",
                text: "Please enter a 10 digit phone number!",
                icon: "error",
                timer: 1500
            })
            return;
        }

        if(!isPasswordValid) {
            setIsPasswordValid(false);
            return;
        }

        setIsPasswordValid(true);

        Ajax.request({
            url:"/JudgeAutos/checkEmailAvailable",
            jsonData:{email:formData.email},
            success:function(reply) {
                if(reply.data) {
                    Ajax.request({
                        url:"/JudgeAutos/createAccount",
                        jsonData: formData,
                        success:function() {
                            document.location.href = "/Login?AccountCreated=true";
                        }
                    });
                } else {
                    swal.fire({
                        title:"Invalid email!",
                        html:"Please use a different email, or <a href='/Contact' style='text-decoration: underline; color: blue';>Contact Us</a>",
                        icon:"error"
                    });
                }
            }
        });
    }

    return(
        <div className="w-full sm:w-1/2 flex-col text-center">
            <div className="grid grid-cols-1 gap-4 m-4 py-5 border-solid border-2 rounded-2xl">
                <h1 className="text-5xl">Create Account</h1>
                <Spacer y={5} />
                <Form onSubmit={createAccount}>
                    <Input
                        isRequired
                        id="firstName"
                        label="First Name"
                        variant="bordered"
                        className="w-2/3 mx-auto"
                        required={true}
                    />
                    <Spacer y={2} />
                    <Input
                        isRequired
                        id="lastName"
                        label="Last Name"
                        variant="bordered"
                        className="w-2/3 mx-auto"
                        required={true}
                    />
                    <Spacer y={2} />
                    <Input
                        isRequired
                        id="email"
                        label="Email"
                        variant="bordered"
                        className="w-2/3 mx-auto"
                        type={"email"}
                        required={true}
                    />
                    <Spacer y={2} />
                    <Input
                        isRequired
                        id="phone"
                        label="Phone Number"
                        variant="bordered"
                        className="w-2/3 mx-auto"
                        type="number"
                        inputMode="tel"
                        required={true}
                    />
                    <Spacer y={2} />
                    <Input
                        isRequired
                        id="password"
                        label="Password"
                        type={isVisible ? "text" : "password"}
                        required={true}
                        isInvalid={!isPasswordValid}
                        errorMessage={!isPasswordValid ? "Password must be at least 9 characters long" : ""}
                        variant="bordered"
                        className="w-2/3 mx-auto"
                        placeholder="P@zZw0rD"
                        endContent={
                            <button className="focus:outline-none" type="button" onClick={toggleVisibility}></button>
                        }
                    />
                    <Spacer y={2} />
                    <Input
                        id="organization"
                        label="Organization Name"
                        variant="bordered"
                        className="w-2/3 mx-auto"
                        required={false}
                    />
                    <Spacer y={2} />              
                    <p>
                        Already Have an Account? <Link href="/Login"><u>Login</u></Link>
                    </p>
                    <Spacer y={5} />
                    <Button
                        color="primary"
                        type={"submit"}
                        isDisabled={clientInformation.userInformation.loggedIn === true}
                    >
                        Create Account
                    </Button>
                </Form>
                {/*<Button sx={{"margin-top":"1rem"}} onClick={loginMicrosoft}><img className={"login-icon"} src={require("../images/microsoft.png")} width={16} height={16}  alt={"Microsoft"}/>Login With Microsoft</Button>*/}
            </div>
        </div>
    )
}