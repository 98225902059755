import React, {
    useEffect,
    useRef,
    useState
} from "react";
import {
    Form,
    useNavigate
} from 'react-router-dom';
import Ajax from "../inc/js/Ajax";
import {
    Bulb_4_Blue,
    Bulb_6_Blue,
    PlusCircle_8_Blue,
    Trash_8_Red
} from "../inc/images/svgs";
import {
    Button,
    Divider,
    Input,
    Popover,
    PopoverTrigger,
    PopoverContent,
    Spacer,
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell,
} from "@nextui-org/react";
import swal from "sweetalert2";

export default function RegistrationForm({clientInformation}) {  
    const navigate = useNavigate();
    const [formDetails, setFormDetails] = useState({});
    const [formDetailUpdates, setFormDetailUpdates] = useState({});    
    const [formData, setFormData] = useState({});
    const [formName, setFormName] = useState();
    const [isBasicForm, setIsBasicForm] = useState(true);
    
    const [formChanges, setFormChanges] = useState([]);
    const [formUpdated, setFormUpdated] = useState(false);
    const [formAdditions, setFormAdditions] = useState([]);

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [isHovered, setIsHovered] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 640);

    useEffect(() => {
        if (clientInformation.userInformation.loggedIn === false) {
            navigate("/Login");
        } else {
            const queryParams = new URLSearchParams(window.location.search);
            const param = queryParams.get("registrationFormId") || localStorage.getItem("registrationFormId");;
            
            if (!param) {
                swal.fire({
                    title: "Whoopsie Daisy",
                    text: "Missing Registration Form Id, please try again.",
                    icon: "error",
                    didClose:(() => {
                        navigate("/Forms");
                    })
                })
            } else {
                if (window.history.replaceState) {
                    localStorage.setItem("registrationFormId", param);
                    window.history.replaceState(null, null, window.location.pathname);
                }

                Ajax.request({
                    url:"/JudgeAutos/readForm",
                    jsonData: {
                        form: "registrationforms",
                        formId: param
                    },
                    success:function(reply) {
                        setFormDetails(reply.data.formDetails);
                        setFormData(reply.data.formData);
                        setFormName(reply.data.formDetails.formName);
                        setIsBasicForm(reply.data.formDetails.type !== "custom");
                    }
                });
            }
        }
    }, [clientInformation]);

    useEffect(() => {
        const handleResize = () => {
            const newWidth = window.innerWidth;
            const isNowMobile = newWidth < 640;
    
            if (Math.abs(newWidth - screenWidth) > 100) {
                setScreenWidth(newWidth);
                setIsMobile(isNowMobile);
            }
        };
    
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [screenWidth]);

    const updateForm = () => {
        return new Promise((resolve, reject) => {
            let usedSequences = new Set();
            
            for (const [key, change] of Object.entries(formChanges)) {
                if (change.sequence) {
                    let seq = Number(change.sequence);

                    if (isNaN(seq)) {
                        swal.fire({
                            title: "Invalid Order Field",
                            text: `Order fields must be numeric, "${change.sequence}" is invalid`,
                            icon: "error"
                        });
                        return;
                    }
        
                    if ([0, 1, 2, 3].includes(seq)) {
                        swal.fire({
                            title: "Invalid Order Field",
                            text: `${seq} is reserved for Fixed Fields`,
                            icon: "error"
                        });
                        return;
                    }

                    if (seq > 98) {
                        swal.fire({
                            title: "Max Order Value Reached",
                            text: `${seq} exceeds the max value of 98`,
                            icon: "error"
                        });
                        return;
                    }
        
                    while (usedSequences.has(seq)) {
                        seq++;
                    }
        
                    usedSequences.add(seq);
                    change.sequence = seq;
                }
            }

            for (const addition of formAdditions) {
                if (addition.sequence) {
                    let seq = Number(addition.sequence);

                    if (isNaN(seq)) {
                        swal.fire({
                            title: "Invalid Field Order Found",
                            text: `Field "${addition.attribute}" must be numeric`,
                            icon: "error"
                        });
                        return;
                    }
        
                    if ([0, 1, 2, 3].includes(seq)) {
                        swal.fire({
                            title: "Invalid Field Order",
                            text: `Field "${addition.attribute}" cannot be set to ${seq}`,
                            icon: "error"
                        });
                        return;
                    }

                    if (seq > 98) {
                        swal.fire({
                            title: "Max Order Reached",
                            text: `Field "${addition.attribute}'s" order cannot exceed 98`,
                            icon: "error"
                        });
                        return;
                    }
        
                    while (usedSequences.has(seq)) {
                        seq++;
                    }
        
                    usedSequences.add(seq);
                    addition.sequence = seq;
                }
            }
            
            const preppedFormChanges = Object.entries(formChanges).map(([key, change]) => ({
                ...formData[key],
                ...change
            }));
    
            const preppedFormAdditions = [...formAdditions];
    
            const preppedInfoUpdates = Object.keys(formDetailUpdates).length > 0 && [{
                formName: formDetailUpdates.formName || formDetails.formName,
                formDescription: formDetailUpdates.formDescription || formDetails.formDescription
            }];

            Ajax.request({
                url: "/JudgeAutos/updateForm",
                jsonData: {
                    registrationFormId: formDetails["registrationFormId"],
                    formType: "registration",
                    formAdditions: preppedFormAdditions,
                    formChanges: preppedFormChanges,
                    formInfoUpdates: preppedInfoUpdates
                },
                success: function(reply) {
                    swal.fire({
                        title: `${reply.data}`,
                        text: `Changes saved to ${formDetails.formName}`,
                        icon: "success",
                        timer: 3000,
                        didClose: () => {
                            window.location.href = `/RegistrationForm?registrationFormId=${formDetails["registrationFormId"]}`;
                        }
                    });
                    resolve(reply);
                },
                error: function(error) {
                    reject(error);
                }
            });
        });
    };

    const deleteFormDataEntry = async (registrationFormDataId) => {
        if (formUpdated) {
            try {
                await updateForm();
            } catch (error) {
                swal.fire({
                    title: "Error updating form",
                    text: error,
                    icon: "error",
                    didClose: () => {
                        window.location.href = `/RegistrationForm?registrationFormId=${formDetails["registrationFormId"]}`;
                    }
                });
            }
        }
        
        Ajax.request({
            url:"/JudgeAutos/deleteFormDataEntry",
            jsonData: {
                formDataId: registrationFormDataId,
                formType: "registration"
            },
            success: function(reply) {
                swal.fire({
                    title: reply.data,
                    text: `Field deleted from ${formName}`,
                    icon: "success",
                    didClose: () => {
                        window.location.href = `/RegistrationForm?registrationFormId=${formDetails["registrationFormId"]}`;
                    }
                });
            }
        });
    }

    const addRow = () => {
        setFormAdditions((prev) => ([
            ...prev,
            {
                attribute: '',
                attributeDescription: '',
                sequence: ''
            }
        ]));
    }

    const handleUpdateNewRow = (index, id, value) => {
        setFormAdditions((prev) =>
            prev.map((row, rowIndex) =>
                rowIndex === index ? { ...row, [id]: value } : row
            )
        );
        setFormUpdated(true);
    };

    const deleteFormEntryAddition = (indexToRemove) => {
        setFormAdditions((prevAdditions) => 
            prevAdditions.filter((_, index) => index !== indexToRemove)
        );
    };

    const handleFormChanges = (key, id, value) => {
        setFormData((prev) => ({
            ...prev,
            [key]: {
                ...prev[key],
                [id]: value
            }
        }))

        setFormChanges((prev) => ({
            ...prev,
            [key]: {
                ...prev[key],
                [id]: value
            }
        }))

        setFormUpdated(true);
    };

    const handleFormInfoUpdates = (key, value) => {
        setFormUpdated(true);

        setFormDetails((prev) => ({
            ...prev,
            [key]: value
        }));

        setFormDetailUpdates((prev) => ({
            ...prev,
            [key]: value
        }));
    }

    const handleListHover = (index) => {
        setIsHovered(index);
    }

    const handleListUnhover = () => {
        setIsHovered(false);
    }

    // WORK AROUND FOR NEXTUI ISSUE 1968
    const dummyKeyboardDelegate = Object.fromEntries(
        [
          "getKeyBelow",
          "getKeyAbove",
          "getKeyLeftOf",
          "getKeyRightOf",
          "getKeyPageBelow",
          "getKeyPageAbove",
          "getFirstKey",
          "getLastKey",
          // HAVE TO ignore this one
          // "getKeyForSearch"
        ].map((name) => [name, () => null]),
      );

    return(
        <div className="flex-col text-center">
            {formDetails && (
                <div>
                    <h1 className="text-3xl">{formName}</h1>
                </div>
            )}
            <Spacer y={10} />
            {formDetails && (
                <div>
                    <div>
                        <Form className="grid grid-cols-2 gap-2 place-content-center">
                            <Input
                                isReadOnly={isBasicForm}
                                key="formName"
                                id="formName"
                                label="Name"
                                value={formDetails.formName}
                                onChange={(e) => handleFormInfoUpdates(e.target.id, e.target.value)}
                                variant="bordered"
                            />
                            <Input
                                isReadOnly={isBasicForm}
                                key="formDescription"
                                id="formDescription"
                                label="Description"
                                value={formDetails.formDescription}
                                onChange={(e) => handleFormInfoUpdates(e.target.id, e.target.value)}
                                variant="bordered"
                            />
                            <Input
                                isReadOnly
                                key="formType"
                                id="formType"
                                label="Type"
                                value={`${formDetails.type ? formDetails.type.charAt(0).toUpperCase() + formDetails.type.slice(1) : ''} Registration Form`}
                                variant="flat"
                            />
                            <Input
                                isReadOnly
                                key="dateCreated"
                                id="dateCreated"
                                label="Date Created"
                                value={new Date(formDetails.dateCreated).toLocaleDateString("en-US")}
                                variant="flat"
                            />
                        </Form>
                    </div>
                    <Spacer y={3} />
                    <div>
                        <Button
                            className={isBasicForm && "hidden"}
                            onClick={() => {
                                if (formUpdated) {
                                    updateForm();
                                } else {
                                    swal.fire({
                                        title: "No changes made",
                                        text: "Please make your changes before saving",
                                        icon: "error",
                                        timer: 1500
                                    })
                                }
                            }}
                            color="primary"
                        >
                            Save
                        </Button>
                    </div>
                </div>
            )}

            <Spacer y={5} />
            <Divider />
            <Spacer y={5} />
            
            <Table
                isStriped
                topContent={
                    <div className="text-xs text-left flex items-center gap-2">
                        <Popover>
                            <PopoverTrigger>
                                <Button isIconOnly variant="light" size="sm">
                                    <Bulb_4_Blue />
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent>
                            <div className="px-1 py-2">
                                <div className="flex items-center justify-center text-center text-small font-bold">
                                    <Bulb_6_Blue />What's this?
                                </div>
                                <div className="text-tiny">
                                    Fixed Fields are required and cannot be changed
                                </div>
                            </div>
                            </PopoverContent>
                     </Popover>
                        Fixed Fields: Number, Name, Phone Number, & Class
                    </div>

                }
                bottomContent={
                    !isBasicForm && (
                        <div className="flex justify-center">
                            <Button
                                isIconOnly
                                variant="light"
                                size="sm"
                                onClick={addRow}
                            >
                                <PlusCircle_8_Blue />
                            </Button>
                        </div>
                    )
                }
                keyboardDelegate={dummyKeyboardDelegate}
                aria-label="Event registrations"
            >
                <TableHeader>
                    <TableColumn className="table-cell text-center">Field</TableColumn>
                    <TableColumn className="table-cell text-center">Description</TableColumn>
                    <TableColumn className="table-cell text-center">Form Order</TableColumn>
                </TableHeader>
                <TableBody>
                    {Object.keys(formData).length > 0 && (
                        Object.entries(formData).map(([key, value]) => (
                            <TableRow
                                isReadOnly = {isBasicForm}
                                key={key}
                                onChange={(e) => handleFormChanges(key, e.target.id, e.target.value)}
                                onMouseEnter={() => handleListHover(key)}
                                onMouseLeave={handleListUnhover}
                            >
                                <TableCell key={`${key}-attribute-cell`} >
                                    <Input
                                        isReadOnly={isBasicForm || ["Number", "Name", "Phone Number", "Class"].includes(formData[key]["attribute"])}
                                        key={`${key}-attribute`}
                                        id="attribute"
                                        type="text"
                                        color="primary"
                                        variant="underlined"
                                        value={formData[key]["attribute"]}
                                        startContent={["Number", "Name", "Phone Number", "Class"].includes(formData[key]["attribute"]) && <Bulb_4_Blue />}
                                        classNames={{input: "text-center bg-transparent", inputWrapper: ["border-0", "shadow-none"]}}
                                    />
                                </TableCell>
                                <TableCell key={`${key}-attributeDescription-cell`} >
                                    <Input
                                        isReadOnly={isBasicForm || ["Number", "Name", "Phone Number", "Class"].includes(formData[key]["attribute"])}
                                        key={`${key}-attributeDescription`}
                                        id="attributeDescription"
                                        type="text"
                                        color="primary"
                                        variant="underlined"
                                        value={formData[key]["attributeDescription"]}
                                        classNames={{input: "text-center bg-transparent", inputWrapper: ["border-0", "shadow-none"]}}
                                    />
                                </TableCell>
                                <TableCell key={`${key}-sequence-cell`} >
                                    <Input
                                        isReadOnly={isBasicForm || ["Number", "Name", "Phone Number", "Class"].includes(formData[key]["attribute"])}
                                        key={`${key}-sequence`}
                                        id="sequence"
                                        type="tel"
                                        color="primary"
                                        variant="underlined"
                                        value={formData[key]["attribute"] === "Class" ? "Last" : formData[key]["sequence"]}
                                        classNames={{input: "text-center bg-transparent", inputWrapper: ["border-0", "shadow-none"]}}
                                        endContent={
                                            !isBasicForm && !(["Number", "Name", "Phone Number", "Class"].includes(formData[key]["attribute"])) && (
                                                <div
                                                    className={`
                                                        transition-opacity
                                                        duration-200
                                                        ${
                                                            isHovered === key || isMobile ?
                                                            'opacity-100' :
                                                            'opacity-0'
                                                        }
                                                    `}>
                                                    <Button isIconOnly variant="light" size="sm" onClick={() => deleteFormDataEntry(formData[key]["registrationFormDataId"])}>
                                                        <Trash_8_Red />
                                                    </Button>
                                                </div>
                                            )
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                        ))
                    )}
                    {!isBasicForm &&
                        Object.keys(formAdditions).map((row, index) => (
                            <TableRow
                                onMouseEnter={() => handleListHover(index)}
                                onMouseLeave={handleListUnhover}
                            >
                                <TableCell key={`attribute${index}`}>
                                    <Input
                                        isRequired
                                        id="attribute"
                                        placeholder="Attribute"
                                        type="text"
                                        color="primary"
                                        variant="underlined"
                                        value={formAdditions[index].attribute}
                                        onChange={(event) => handleUpdateNewRow(index, event.target.id, event.target.value)}
                                        classNames={{ input: "text-center bg-transparent", inputWrapper: ["border-0", "shadow-none"] }}
                                    />
                                </TableCell>
                                <TableCell key={`description${index}`}>
                                    <Input
                                        id="attributeDescription"
                                        placeholder="Description"
                                        type="text"
                                        color="primary"
                                        variant="underlined"
                                        value={formAdditions[index].attributeDescription}
                                        onChange={(event) => handleUpdateNewRow(index, event.target.id, event.target.value)}
                                        classNames={{ input: "text-center bg-transparent", inputWrapper: ["border-0", "shadow-none"] }}
                                    />
                                </TableCell>
                                <TableCell key={`sequence${index}`}>
                                    <Input
                                        isRequired
                                        id="sequence"
                                        placeholder="Order"
                                        type="tel"
                                        color="primary"
                                        variant="underlined"
                                        value={formAdditions[index].sequence}
                                        onChange={(event) => handleUpdateNewRow(index, event.target.id, event.target.value)}
                                        classNames={{ input: "text-center bg-transparent", inputWrapper: ["border-0", "shadow-none"] }}
                                        endContent={ 
                                            <div
                                                className={`
                                                    transition-opacity
                                                    duration-200
                                                    ${
                                                        isHovered === index || isMobile ?
                                                        'opacity-100' :
                                                        'opacity-0'
                                                    }
                                                `}>
                                                <Button isIconOnly size="sm" variant="light" onClick={() => deleteFormEntryAddition(index)}>
                                                    <Trash_8_Red />
                                                </Button>
                                            </div>
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
            <Spacer y={4} />
            <div>
                <Button
                    className={isBasicForm && "hidden"}
                    onClick={() => {
                        if (formUpdated) {
                            updateForm();
                        } else {
                            swal.fire({
                                title: "No changes made",
                                text: "Please make your changes before saving",
                                icon: "error",
                                timer: 1500
                            })
                        }
                    }}
                    color="primary"
                >
                    Save
                </Button>
            </div>
        </div>
    );
}