import React, {
    useEffect,
    useState
} from 'react';
import {
    useNavigate
} from 'react-router-dom';
import {
    Button,
    Spacer,
    Input,
} from "@nextui-org/react";
import Ajax from "../inc/js/Ajax";
import swal from "sweetalert2";

export default function ForgotPassword({clientInformation}) {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');

    useEffect(() => {
        if (clientInformation.userInformation.loggedIn === true) {
            navigate("/MyAccount");
        }
    }, [clientInformation]);

    const resetPassword = (event) => {
        const form = {
            email:document.getElementById("email").value
        };

        event.preventDefault();
        Ajax.request({
            url:"/JudgeAutos/sendResetPasswordEmail",
            jsonData:{
                email:email,
                ipAddress: clientInformation.ipAddress
            },
            success:function() {
                swal.fire({
                    title: "Email Sent!",
                    text: "A password reset email has been sent to " + email + ". If you cannot find the email, check your spam and junk folders.",
                    icon: "success",
                    didClose: () => {
                        document.location.href = "/Login"
                    }
                })
            }
        });
    };   

    function handleEnter(event) {
        if(event.key === "Enter") {
            resetPassword(event);
        }
    }

    return (
        <div className="sm:w-1/2 text-center">
            <div className="grid grid-cols-1 gap-4 m-4 py-5 border-solid border-2 rounded-2xl" >
                <h1 className="text-5xl">Reset Password</h1>
                <Spacer y={5} />
                <Input
                    isRequired
                    id="email"
                    label="Email Address"
                    type="email"
                    variant="bordered"
                    margin="normal"
                    required={true}
                    value={email}
                    className="w-3/4 mx-auto"
                    onKeyDown={handleEnter}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <Button
                    color="primary"
                    className="w-1/3 mx-auto"
                    onClick={resetPassword}
                >
                    Reset
                </Button>
            </div>
        </div>
    );
}