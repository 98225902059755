import React, {
    useEffect,
    useState
} from "react";
import { 
    Form
} from 'react-router-dom';
import Ajax from "../inc/js/Ajax";
import swal from "sweetalert2";
import {
    Button,
    Divider,
    Input,
    Link,
    Spacer,
    Tab,
    Tabs,
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell
} from "@nextui-org/react";

export default function Judging() {
    const [numberEntry, setNumberEntry] = useState({});
    const [formData, setFormData] = useState([]);
    const [judgeInfo, setJudgeInfo] = useState([]);
    const [judgingInfo, setJudgingInfo] = useState([]);
    const [judgedRegistrations, setJudgedRegistrations] = useState([]);
    const [unjudgedRegistrations, setUnjudgedRegistrations] = useState([]);

    useEffect(() => {     
        Ajax.request({
            url: '/JudgeAutos/loadJudge',
            success:function(reply) {
                if(reply.data) {
                    setJudgeInfo(reply.data.judgeInfo);
                    setUnjudgedRegistrations(reply.data.unjudgedRegistrations);

                    if (reply.data.judgeInfo.admin === 1) {
                        setJudgedRegistrations(reply.data?.judgedRegistrations);
                    }
                }
            },
            failure:function(reply) {
                swal.fire({
                    title: "Oops!",
                    text: reply,
                    icon: "error",
                    didClose: () => {
                        document.location.href = "/"
                    }
                })
            }
        });
    }, [])

    const handleNumberEntry = (number) => {
        const searchEntries = judgeInfo.admin === 0 ? unjudgedRegistrations : { ...judgedRegistrations, ...unjudgedRegistrations };
        const registrationId = Object.keys(searchEntries).find(
            (key) => searchEntries[key].Number === number
        ) || "";

        setNumberEntry({
            registrationId: registrationId,
            number: number
        })
    }

    const handleInputChange = (attribute, value) => {
        setFormData(prevState => ({
            ...prevState,
            [attribute]: value
        }));
    };

    const loadJudging = () => {
        if (numberEntry?.number && numberEntry?.registrationId) {
            Ajax.request({
                url: '/JudgeAutos/loadJudging',
                jsonData: {
                    registrationId: numberEntry.registrationId
                },
                success:function(reply) {
                    if(reply.data) {
                        if (Object.keys(reply.data.registrationData).length === 0) {
                            swal.fire({
                                title:`Registration ${numberEntry.number} is invalid.`,
                                html:"Double check your entry and the registration number",
                                icon:"error",
                                didClose: () => {
                                    document.location.href = "/Judging"
                                }
                            })
                        } else {
                            setFormData(Object.entries(reply.data.judgeData).reduce((acc, [key, category]) => {
                                const [categoryName, categoryData] = Object.entries(category)[0];
                                acc[categoryName] = categoryData.value;
                                return acc;
                            }, {}));

                            setJudgingInfo(reply.data);
                        }
                    }
                }
            });
        } else if (!numberEntry?.number) {
            swal.fire({
                title: "Empty selection",
                text: "Please enter a registration number",
                icon: "error"
            })
        } else {
            judgeInfo.admin === 0 ? (
                swal.fire({
                    title: "Invalid selection",
                    text: `Registration ${numberEntry.number} has been judged or does not exist`,
                    icon: "error",
                    didClose: () => {
                        document.location.href = "/Judging"
                    }
                })
            ) : (
                swal.fire({
                    title: "Invalid selection",
                    text: `Registration ${numberEntry.number} does not exist`,
                    icon: "error",
                    didClose: () => {
                        document.location.href = "/Judging"
                    }
                })
            )
        }
    }

    const submitJudging = () => {
        if (Object.keys(formData).length === Object.keys(judgingInfo.judgeData).length) {
            Ajax.request({
                url:'/JudgeAutos/submitJudging',
                jsonData: {
                    judgingData: [formData],
                    registrationId: Object.keys(judgingInfo.registrationData)[0]
                },
                success:function(reply) {
                    if(reply.data) {
                        swal.fire({
                            title: `${reply.data}`,
                            text: `Registration ${judgingInfo.registrationData[Object.keys(judgingInfo.registrationData)[0]].Number} was judged successfully`,
                            icon: "success",
                            timer: 3000,
                            showConfirmButton: false
                        }).then(() => (
                            window.location.reload()
                        ))
                    }
                }
            });
        } else {
            swal.fire({
                title: "Missing value",
                text: "Please enter a value in all fields",
                icon: "error",
                timer: 1500
            })

            return;
        }
    };

    return(
        <div className="flex-col text-center">
            <Link href="/Judging" color="foreground">
                <h1 className="text-5xl">Judging</h1>
            </Link>
            {judgeInfo && !judgingInfo.judgeData && (
                <>
                    <Spacer y={10} />
                    <p>
                        Welcome {judgeInfo.name}! <br />
                        Please enter a registration number.
                    </p>
                </>
            )}
            
            <Spacer y={5} />
            {judgeInfo && !judgingInfo.judgeData && (
                <div>
                    <Form>
                        <Input 
                            isRequired
                            label="Registration Number"
                            variant="bordered"
                            type="number"
                            inputMode="tel"
                            onChange={(e) => handleNumberEntry(e.target.value)}
                        />
                        <Spacer y={2} />
                        <Button
                            onClick={loadJudging}
                            color="primary"
                        >
                            Enter
                        </Button>
                    </Form>
                    <Divider className="my-10" />
                    {judgeInfo.admin === 1 ? (
                        <Tabs>
                            <Tab key="unjudgedRegistrations" title="Unjudged" aria-label="Unjudged Registrations Tab">
                                {Object.values(unjudgedRegistrations).some(obj => Object.keys(obj).length > 0) ? (
                                    <Table isStriped aria-label="Unjudged Table">
                                        <TableHeader>
                                            {Object.keys(Object.values(unjudgedRegistrations)[0])
                                                .filter(
                                                    key =>
                                                        key === "Number" ||
                                                        key.toLowerCase().includes("year") ||
                                                        key.toLowerCase().includes("model") ||
                                                        key.toLowerCase().includes("color")
                                                )
                                                .map((filteredKey, index) => (
                                                    <TableColumn key={index}>{filteredKey}</TableColumn>
                                                ))}
                                        </TableHeader>
                                        <TableBody>
                                        {Object.values(unjudgedRegistrations).map((item, index) => (
                                            <TableRow>
                                                    {Object.keys(item)
                                                    .filter(
                                                        key =>
                                                            key === "Number" ||
                                                            key.toLowerCase().includes("model") ||
                                                            key.toLowerCase().includes("year") ||
                                                            key.toLowerCase().includes("color")
                                                    )
                                                    .map((filteredKey, index) => (
                                                        <TableCell key={index}>{item[filteredKey]}</TableCell>
                                                    ))}
                                            </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                ) : (
                                    <>
                                        <Spacer y={5} />
                                        <p>
                                            Nothing to judge!
                                        </p>
                                        <Spacer y={5} />
                                        <Button
                                            onClick={() => window.location.reload()}
                                            color="primary"
                                        >
                                            Refresh
                                        </Button>
                                    </>
                                )}
                            </Tab>
                            <Tab key="judgedRegistrations" title="Judged" aria-label="Judged Registrations Tab">
                                {Object.values(judgedRegistrations).some(obj => Object.keys(obj).length > 0) ? (
                                    <Table isStriped aria-label="Unjudged Table">
                                        <TableHeader>
                                            {Object.keys(Object.values(judgedRegistrations)[0])
                                                .filter(
                                                    key =>
                                                        key === "Number" ||
                                                        key.toLowerCase().includes("year") ||
                                                        key.toLowerCase().includes("model") ||
                                                        key.toLowerCase().includes("color")
                                                )
                                                .map((filteredKey, index) => (
                                                    <TableColumn key={index}>{filteredKey}</TableColumn>
                                                ))}
                                        </TableHeader>
                                        <TableBody>
                                        {Object.values(judgedRegistrations).map((item, index) => (
                                            <TableRow>
                                                    {Object.keys(item)
                                                    .filter(
                                                        key =>
                                                            key === "Number" ||
                                                            key.toLowerCase().includes("model") ||
                                                            key.toLowerCase().includes("year") ||
                                                            key.toLowerCase().includes("color")
                                                    )
                                                    .map((filteredKey, index) => (
                                                        <TableCell key={index}>{item[filteredKey]}</TableCell>
                                                    ))}
                                            </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                ) : (
                                    <>
                                        <Spacer y={5} />
                                        <p>
                                            Nothing has been judged!
                                        </p>
                                        <Spacer y={5} />
                                        <Button
                                            onClick={() => window.location.reload()}
                                            color="primary"
                                        >
                                            Refresh
                                        </Button>
                                    </>
                                )}
                            </Tab>
                        </Tabs>
                    ) : (
                        <>
                            <h2 className="text-2xl">Unjudged Registrations</h2>
                            <Spacer y={5} />
                            {Object.values(unjudgedRegistrations).some(obj => Object.keys(obj).length > 0) ? (
                                <Table isStriped aria-label="Unjudged Table">
                                    <TableHeader>
                                        {Object.keys(Object.values(unjudgedRegistrations)[0])
                                            .filter(
                                                key =>
                                                    key === "Number" ||
                                                    key.toLowerCase().includes("year") ||
                                                    key.toLowerCase().includes("model") ||
                                                    key.toLowerCase().includes("color")
                                            )
                                            .map((filteredKey, index) => (
                                                <TableColumn key={index}>{filteredKey}</TableColumn>
                                            ))}
                                    </TableHeader>
                                    <TableBody>
                                    {Object.values(unjudgedRegistrations).map((item, index) => (
                                        <TableRow>
                                                {Object.keys(item)
                                                .filter(
                                                    key =>
                                                        key === "Number" ||
                                                        key.toLowerCase().includes("model") ||
                                                        key.toLowerCase().includes("year") ||
                                                        key.toLowerCase().includes("color")
                                                )
                                                .map((filteredKey, index) => (
                                                    <TableCell key={index}>{item[filteredKey]}</TableCell>
                                                ))}
                                        </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            ) : (
                                <>
                                    <Spacer y={5} />
                                    <p>
                                        Nothing to judge!
                                    </p>
                                    <Spacer y={5} />
                                    <Button
                                        onClick={() => window.location.reload()}
                                        color="primary"
                                    >
                                        Refresh
                                    </Button>
                                </>
                            )}
                        </>
                    )}
                </div>
            )}
            {judgingInfo.judgeData && (
                <div>
                    <h3 className="text-3xl">Registration {Object.values(judgingInfo.registrationData)[0].Number}</h3>
                    <Spacer y={2} />
                    <div className="grid grid-cols-2 gap-2 place-content-center">
                        {Object.entries(judgingInfo.registrationData).map(([key, data]) => (
                            Object.entries(data).map(([attribute, value]) => (
                                !attribute.toLowerCase().includes("name") && !attribute.toLowerCase().includes("number") && !attribute.toLowerCase().includes("phone") && (
                                    <Input
                                        isReadOnly
                                        key={attribute}
                                        label={attribute}
                                        value={value}
                                        variant="flat"
                                    />
                                )
                            ))
                        ))}
                    </div>
                    <Divider className="my-10" />
                    <h3 className="text-3xl">Judge carefully!</h3>
                    <Spacer y={2} />
                    <Form key="judge">
                        {Object.entries(judgingInfo.judgeData).map(([key, data]) => (
                            Object.entries(data).map(([attribute, attributeData]) => (
                                <>
                                    <Input
                                        isRequired
                                        key={attribute}
                                        label={attribute}
                                        variant="bordered"
                                        type="number"
                                        inputMode="tel"
                                        value={`${formData[attribute]}`}
                                        endContent={`/${attributeData.maxScore}`}
                                        onFocus={(e) => {
                                            if (formData[attribute] === 0 || formData[attribute] === "0") {
                                                handleInputChange(attribute, "");
                                            }
                                        }}
                                        onChange={(e) => handleInputChange(attribute, e.target.value)}
                                    />
                                    { <Spacer y={1} /> }
                                </>
                            ))
                        ))}
                        <Input
                            isReadOnly
                            value={
                                Object.values(formData).reduce((total, value) => total + Number(value), 0)
                            }
                            key="Total Score"
                            label="Total Score"
                            variant="flat"
                            endContent={`
                                /${Object.values(judgingInfo.judgeData).reduce((total, category) => {
                                    const categoryData = Object.values(category)[0];
                                    return total + (categoryData.maxScore || 0);
                                }, 0)}
                            `}
                        />
                        <Spacer y={4} />
                        <div className="grid grid-cols-2 gap-4 place-content-center">
                            <Button
                                onClick={() => {window.location.reload()}}
                                color="danger"
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={submitJudging}
                                color="primary"
                            >
                                Submit
                            </Button>
                        </div>
                    </Form>
                </div>
            )}
        </div>
    );
}